import { FF_CALCOM_EMBED_PARAMS, FF_DISABLE_CONSULTATION_BUTTONS } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { generateCalcomActionEmail } from '@/pages/consultations/utils';
import AuthService from '@/services/authentication.service';
import type { ButtonProps } from '@/ui-library';
import type { PropsWithChildren } from 'react';
import CalendarPopupButton from './CalendarPopupButton';

export type BookConsultationButtonProps = PropsWithChildren<{
  buttonProps?: Omit<ButtonProps, 'children'>;
  disabled?: boolean;
  handleBookingSuccess?: () => void;
  handleRescheduleSuccess?: () => void;
  handleCancelSuccess?: () => void;
  calcomUrl?: string;
  onOpen?: () => void;
  testId?: string;
  togglePrimaryColours?: boolean;
}>;

const disabledButtonTooltip =
  'Bookings are temporarily disabled while we update our system. We appreciate your patience.';

export default function BookConsultationButton({
  buttonProps,
  disabled,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
  calcomUrl,
  onOpen,
  testId,
  togglePrimaryColours = false,
  children
}: BookConsultationButtonProps) {
  let calcomLink = calcomUrl;
  const user = AuthService.getUser();
  const userId = user?.user?.id;
  const { flags } = useFeatureFlags();
  const ffDisableConsultationButtons =
    flags[FF_DISABLE_CONSULTATION_BUTTONS] || ffDefaults[FF_DISABLE_CONSULTATION_BUTTONS];
  const ffEnabledCalcomEmbedParams = flags[FF_CALCOM_EMBED_PARAMS];

  const prefillCalCom = {
    email: user?.user?.email,
    name: `${user?.user?.first_name} ${user?.user?.last_name}`,
    phoneNumber: user?.user?.phone
  };

  if (!calcomLink) {
    return null;
  }

  if (userId && ffEnabledCalcomEmbedParams) {
    calcomLink = `${calcomLink}?${generateCalcomActionEmail(userId)}`;
  }

  return (
    <CalendarPopupButton
      testId={testId}
      link={calcomLink}
      formData={prefillCalCom}
      onOpen={() => {
        if (onOpen) {
          onOpen();
        }
      }}
      disabled={ffDisableConsultationButtons || disabled}
      buttonProps={buttonProps}
      tooltipProps={{ title: disabledButtonTooltip }}
      handleBookingSuccess={handleBookingSuccess}
      handleRescheduleSuccess={handleRescheduleSuccess}
      handleCancelSuccess={handleCancelSuccess}
      togglePrimaryColours={togglePrimaryColours}
    >
      {children}
    </CalendarPopupButton>
  );
}
