import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import { getOrderCreationReasons } from '@/services/data.service';
import { Box, FormControl, MenuItem, Skeleton, styled, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import OrderDiscounts from '../OrderDiscounts/OrderDiscounts';
import { useFilteredReasons } from './useFilteredReason';

interface OrderCreationReason {
  id: number;
  reason: string;
}

interface GetOrderCreationReasonsResponse {
  data: OrderCreationReason[];
}

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const OrderDetailsReason = () => {
  const { isLoading, isNewOrder, isEditing, isRefill, isAdminInititatedOrder } = useOrderFormMeta();
  const [reasonList, setReasonList] = useState<OrderCreationReason[]>([]);

  const filteredReasons = useFilteredReasons(reasonList);

  const { control, watch } = useFormContext();

  const selectedReasonId = watch('adminOrderCreationReasonId');

  useEffect(() => {
    getOrderCreationReasons().then((response: GetOrderCreationReasonsResponse) => {
      setReasonList((response.data ?? []).sort((a, b) => a.reason.localeCompare(b.reason)));
    });
  }, []);

  if (isLoading) {
    return (
      <>
        <Typography variant="body1" marginBottom={4} data-testid="loading">
          Manual Order Reason
        </Typography>
        <Skeleton width="100%" height={50} sx={{ maxWidth: '50rem' }} variant="rectangular" />
      </>
    );
  }

  if (!isAdminInititatedOrder && !isNewOrder) {
    return null;
  }

  const showOrderDiscounts = [1, 2, 7].includes(selectedReasonId);

  return (
    <>
      <Container>
        <Header variant="body1">Manual Order Reason</Header>
        <FormControl fullWidth>
          <Controller
            name="adminOrderCreationReasonId"
            control={control}
            rules={{ required: 'A reason is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                select
                InputLabelProps={{
                  shrink: true
                }}
                id="order-reason"
                data-testid="order-reason"
                label="Select a reason *"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  readOnly: ((!isNewOrder || !isRefill) && !isEditing) || reasonList.length === 0
                }}
                sx={{ maxWidth: '50rem' }}
                {...field}
              >
                {(isEditing ? filteredReasons : reasonList).map((reason: OrderCreationReason) => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </FormControl>
      </Container>
      {showOrderDiscounts && <OrderDiscounts selectedReasonId={selectedReasonId} />}
    </>
  );
};

export default OrderDetailsReason;
