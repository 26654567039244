import USER_ROLES from '@/constants/userRoles';
import { BRANDS } from '@/types';
import { Receipt } from '@mui/icons-material';

export const useCreateOrderButton = () => {
  return {
    id: 8,
    name: 'Start your order',
    link: '/patient/refill',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: Receipt
  };
};
