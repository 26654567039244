import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { OrderProductOption } from '@/hooks/order/useOrderFormProducts';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useWatch } from 'react-hook-form';

export function AddProductButton({ handleClick }: { handleClick: () => void }) {
  const { isEditing, isNewOrder, isRefill } = useOrderFormMeta();

  const products = useWatch({ name: 'products' });

  const disabled = products.some((product: OrderProductOption) => !product.productId);

  if (!isEditing && !isNewOrder && !isRefill) {
    return null;
  }

  return (
    <Box sx={{ marginLeft: '1rem' }}>
      <Button variant="contained" color="primary" endIcon={<AddIcon />} onClick={handleClick} disabled={disabled}>
        Add Product
      </Button>
    </Box>
  );
}
