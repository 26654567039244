import { FF_DISABLED_ORDER_DISCOUNT_BUTTON, FF_SHOPIFY_DISCOUNT_SUPER_ADMINS } from '@/constants/featureFlags';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { useMemo } from 'react';

interface OrderCreationReason {
  id: number;
  reason: string;
}

export const useFilteredReasons = (reasonList: OrderCreationReason[]) => {
  const { isNewOrder, isPaymentAuthorised } = useOrderFormMeta();
  const { flags } = useFeatureFlags();

  const showReplacementReasons = useMemo(() => {
    return (
      flags[FF_DISABLED_ORDER_DISCOUNT_BUTTON] &&
      (AuthService.isSuperAdmin() || flags[FF_SHOPIFY_DISCOUNT_SUPER_ADMINS]) &&
      (isNewOrder || isPaymentAuthorised)
    );
  }, [flags, isNewOrder, isPaymentAuthorised]);

  const filteredReasons = useMemo(() => {
    return showReplacementReasons
      ? reasonList
      : reasonList.filter(({ reason }) => !reason.toLowerCase().includes('replacement'));
  }, [showReplacementReasons, reasonList]);

  return filteredReasons;
};
