import { jsxs as p, Fragment as k, jsx as n } from "@emotion/react/jsx-runtime";
import { ArrowDropDown as C } from "@mui/icons-material";
import I from "@mui/material/ClickAwayListener";
import v from "@mui/material/Grow";
import A from "@mui/material/MenuItem";
import B from "@mui/material/MenuList";
import E from "@mui/material/Paper";
import L from "@mui/material/Popper";
import { styled as O, alpha as u } from "@mui/material/styles";
import { useState as P, useRef as f, useEffect as R } from "react";
import { B as T } from "../Button/Button.js";
const j = O(B)(({ theme: t }) => ({
  "& .MuiMenuItem-root:not(:last-child)": {
    borderBottom: `1px solid ${u(t.palette.primary.main, 0.5)}`
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: u(t.palette.primary.main, 0.1)
  }
}));
function U({
  buttonLabel: t,
  menuItems: d,
  handleClickMenuItem: l,
  placement: m = "bottom-end",
  variant: h = "outlined",
  color: y = "primary",
  sx: b
}) {
  const [e, i] = P(!1), a = f(null), x = () => {
    i((r) => !r);
  }, s = (r) => {
    a.current && a.current.contains(r.target) || i(!1);
  }, g = (r, o) => {
    l && (s(r), l(o));
  };
  function w(r) {
    r.key === "Tab" ? (r.preventDefault(), i(!1)) : r.key === "Escape" && i(!1);
  }
  const c = f(e);
  return R(() => {
    c.current === !0 && e === !1 && a.current.focus(), c.current = e;
  }, [e]), /* @__PURE__ */ p(k, { children: [
    /* @__PURE__ */ p(
      T,
      {
        sx: { textTransform: "unset", paddingRight: "0.5rem", ...b },
        ref: a,
        "aria-expanded": e ? "true" : void 0,
        "aria-haspopup": "true",
        "aria-label": t,
        onClick: x,
        variant: h,
        color: y,
        children: [
          t,
          /* @__PURE__ */ n(C, {})
        ]
      }
    ),
    /* @__PURE__ */ n(
      L,
      {
        sx: { zIndex: 100 },
        open: e,
        anchorEl: a.current,
        placement: m,
        transition: !0,
        disablePortal: !0,
        children: ({ TransitionProps: r }) => /* @__PURE__ */ n(
          v,
          {
            ...r,
            style: {
              transformOrigin: "left top"
            },
            children: /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(I, { onClickAway: s, children: /* @__PURE__ */ n(
              j,
              {
                autoFocusItem: e,
                id: "composition-menu",
                "aria-labelledby": "composition-button",
                onKeyDown: w,
                children: d.map((o, D) => /* @__PURE__ */ n(
                  A,
                  {
                    disabled: o.isDisabled,
                    onClick: (M) => g(M, o.value ?? ""),
                    children: (o == null ? void 0 : o.children) ?? (o == null ? void 0 : o.label)
                  },
                  D
                ))
              }
            ) }) })
          }
        )
      }
    )
  ] });
}
export {
  U as D
};
