import { FF_SHOPIFY_DISCOUNT_SUPER_ADMINS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Chip } from '@/ui-library';
import moment from 'moment';
import React from 'react';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';

const PatientDiscounts = (props) => {
  const { flags } = useFeatureFlags();
  const redirectToShopify = (shopifyDiscountId) => {
    const { shopifyDiscountsURL, shopifyShippingDiscountAppURL } = settings;
    // only shipping discount available
    window.open(
      `${shopifyDiscountsURL}/${shopifyShippingDiscountAppURL}/${shopifyDiscountId}`,
      '_blank',
      'noopener noreferrer'
    );
  };
  const handleEditModal = (e) => {
    const id = e.target.dataset.key;
    const selected = props.patientDiscounts.find((x) => x.id === Number(id));
    const shopifyDiscountId = selected.PatientDiscountsShopify[0]?.shopify_discount_id;
    redirectToShopify(shopifyDiscountId);
  };

  const deleteDiscount = async (e) => {
    e.preventDefault();
    const id = e.target.dataset.key;
    const selected = props.patientDiscounts.find((x) => x.id === Number(id));
    const shopifyDiscountId = selected.PatientDiscountsShopify[0]?.shopify_discount_id;
    redirectToShopify(shopifyDiscountId);
  };

  const isSuperAdmin = AuthService.isSuperAdmin() || flags[FF_SHOPIFY_DISCOUNT_SUPER_ADMINS];
  return (
    <React.Fragment>
      {props.patientDiscounts.length > 0 && (
        <>
          <p className="mt-5">Patient Discounts</p>
          <small className="text-muted">
            The discounts listed here are either active or inactive, this does not reflect their usage status.
          </small>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Discount Type</th>
                <th>Reason</th>
                <th>Discount Amount</th>
                <th>Product Name</th>
                <th>Status</th>
                <th>Start Date</th>
                <th>End Date</th>
                {isSuperAdmin && (
                  <>
                    <th>Edit</th>
                    <th>Delete</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {props.patientDiscounts.map((disObj, index) => (
                <tr key={index}>
                  <td>
                    {disObj.DiscountType.name}
                    {disObj.is_concession && <Chip sx={{ ml: 1 }} color="primary" label="concession" size="small" />}
                  </td>
                  <td>{disObj.DiscountReason?.reason}</td>
                  <td>
                    {disObj.discount_percentage ? disObj.discount_percentage + '%' : '$' + disObj.discount_amount}
                  </td>
                  <td>{disObj.Product && disObj.discount_type_id === 3 ? disObj.Product.name : '-'}</td>
                  <td>
                    <Chip
                      sx={{ ml: 1 }}
                      color={disObj.active ? 'success' : 'warning'}
                      label={disObj.active ? 'active' : 'inactive'}
                      size="small"
                    />
                  </td>
                  <td>{disObj.start_date ? moment(disObj.start_date).format('DD-MMM-YYYY') : '-'}</td>
                  <td>{disObj.end_date ? moment(disObj.end_date).format('DD-MMM-YYYY') : '-'}</td>
                  {isSuperAdmin && (
                    <>
                      <td>
                        {disObj.active && (
                          <button
                            className="btn btn-primary btn-md d-block font-weight-bold"
                            disabled={
                              props.downloading ||
                              (disObj.order_limit <= 0 && disObj.order_limit !== null) ||
                              disObj.is_concession
                            }
                            onClick={handleEditModal}
                            data-key={disObj.id}
                            data-old={true}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>
                        {disObj.active && (
                          <button
                            className="btn btn-primary btn-md d-block font-weight-bold"
                            onClick={deleteDiscount}
                            disabled={props.downloading || disObj.is_concession}
                            data-key={disObj.id}
                            data-old={true}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default PatientDiscounts;
