import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import AuthService from '@/services/authentication.service';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function OrderFormButtons() {
  const { isEditing, isExistingOrder, isLoading } = useOrderFormMeta();
  const isAdmin = AuthService.isAdmin();
  const {
    reset,
    trigger,
    formState: { isDirty, isLoading: formIsLoading, isSubmitting, isValidating, errors }
  } = useFormContext();

  const handleCancel = () => {
    reset();
  };

  // @ts-expect-error
  const handleSubmit = async (data) => {
    await trigger();
    console.log('data', data);
    console.log('errors', errors);
  };

  const formIsSubmittable = isDirty && !isLoading && !formIsLoading && !isSubmitting && !isValidating;

  if (isLoading || (isAdmin && isExistingOrder && !isEditing)) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '4rem' }}>
      <Stack gap={1}>
        <Stack direction="row" gap={2}>
          {isAdmin && isExistingOrder && (
            <Button variant="outlined" size="large" disabled={isLoading} onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <Button variant="contained" size="large" disabled={!formIsSubmittable} onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">Submit will send invoice</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
