import { Box, Link, styled, Typography } from '@mui/material';
import { MdOutlineWarningAmber } from 'react-icons/md';

const MessageBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
  border: `1px solid ${theme.palette.warning.main}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(10),
  textAlign: 'left'
}));

const IconContainer = styled(Box)(({ theme }) => ({
  height: theme.spacing(5.5),
  width: theme.spacing(5.5),
  padding: `${theme.spacing(1.75)} ${theme.spacing(3)} ${theme.spacing(1.75)} 0`,
  color: theme.palette.warning.main
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(2)} 0`,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  flex: '1 0 0'
}));

function LoginFailure({
  error,
  showSupportText,
  showPharmacistSupportText
}: {
  error: string | null;
  showSupportText?: boolean;
  showPharmacistSupportText?: boolean;
}) {
  if (!error) {
    return null;
  }

  return (
    <MessageBox role="alert">
      <IconContainer>
        <MdOutlineWarningAmber size={22} />
      </IconContainer>
      <TextContainer>
        <Typography>
          <b>{error}</b>

          {showPharmacistSupportText && (
            <span data-testid="support-text"> Contact support if you&apos;re still having issues.</span>
          )}

          {showSupportText && (
            <span data-testid="support-text">
              {' '}
              Please try our{' '}
              <Link
                href="https://support.alternaleaf.com.au/hc/en-au/articles/10869733610639-Technical-support"
                rel="noopener noreferrer"
                target="_blank"
              >
                troubleshooting guide
              </Link>{' '}
              before contacting support.
            </span>
          )}
        </Typography>
      </TextContainer>
    </MessageBox>
  );
}

export default LoginFailure;
