import { getPrescriptionUrl } from '@/services/data.service';
import { Logger } from '@/utils/logger';
import { toast } from '@montugroup/design-system';
import { useState } from 'react';

const logger = new Logger('useOpenPrescription');

export function useOpenPrescription() {
  const [isLoading, setIsLoading] = useState(false);

  const openPrescription = async (prescriptionCode: string) => {
    setIsLoading(true);

    try {
      const response = await getPrescriptionUrl(`downloads/prescriptions/${prescriptionCode}.pdf`);
      if (!response?.data) {
        toast.warn('Prescription not found');
        return;
      }
      window.open(response.data);
    } catch (error) {
      logger.error('Error fetching prescriptions, error:', error);
      toast.error('Error occurred while downloading prescription');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    openPrescription
  };
}
