//! DO NOT EDIT THIS FILE IT IS GENERATED USING:
// pnpm generate-flags

// Default On Variation: true
// Default Off Variation: false
export const FF_REFUND_NON_REFUND_CREDIT = 'refund_non_refund_credit';

// Description: As a Pharmacist, I would like to see the inventory count of products assigned to orders and Available stock in Pharmacy Access.
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACY_STOCK_ENHANCEMENT = 'pharmacy_stock_enhancement';

// Description: Feature toggle for the new Dispense flow introduced in MC-3844 and MC-3589
// Default On Variation: true
// Default Off Variation: false
export const FF_PROCESSING_DISPENSE_FLOW = 'processing_dispense_flow';

// Description: Feature flag for Unable to dispense workflow https://montugroup.atlassian.net/browse/MC-3798
// Default On Variation: true
// Default Off Variation: false
export const FF_UNABLE_TO_DISPENSE = 'unable_to_dispense';

// Description: When this flag is on the banner will be displayed in payment page indicating that there can be intermittent payment issues.
// Default On Variation: Please note possible payment outage on April 22 between 2-5am AEST due to maintenance. Thank you for your patience.
// Default Off Variation: OFF
export const FF_SHOW_PAYMENT_WARNING = 'show_payment_warning';

// Description: This flag enables the 3 new toggles we introduce on montu admin when editing a product (Supply chain risk-Product exclusion from new
// prescriptions-generative scripting)
// Default On Variation: true
// Default Off Variation: false
export const FF_GENERATIVE_SCRIPTING_TOGGLES = 'generative_scripting_toggles';

// Default On Variation: 3
// Default Off Variation: 13.64
export const FF_SHIPPING_PRICE = 'shipping_price';

// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_FORM_DISABLE_EDIT_IF_PAID = 'order_form_disable_edit_if_paid';

// Description: Flag to enable the new endpoint that fixes a bug with discounts
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_NEW_EDIT_ORDER_ENDPOINT = 'use_new_edit_order_endpoint';

// Description: Allows us to quickly disable and re-enable the booking buttons for consultations
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_CONSULTATION_BUTTONS = 'disable_consultation_buttons';

// Description: A flag used to toggle features used in the ERX Paper Script Solution. Also see ERX Token Generation Solution for related flags
// Default On Variation: true
// Default Off Variation: false
export const FF_ERX_PAPER_SCRIPT_SOLUTION = 'erx_paper_script_solution';

// Description: Flag to disable Product Edits in Circuit, getting ready for using Product Edits from Shopify
// https://montugroup.atlassian.net/browse/B2CS-87
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISABLE_PRODUCT_EDIT = 'shopify_disable_product_edit';

// Description: https://montugroup.atlassian.net/browse/B2CS-98
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_SHOPIFY_PRODUCT_IMAGES = 'use_shopify_product_images';

// Description: When enabled all users are shown the maintenance screen
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOW_MAINTENANCE_SCREEN = 'show_maintenance_screen';

// Description: When this flag is enabled patient will be presented with Shopify checkout page instead of Circuit bespoke checkout page
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_CHECKOUT = 'enable_shopify_checkout';

// Description: when enabled allows pharmacist to filter orders on /pharmacist/orders by order status
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACY_FILTER_ORDERS_BY_STATUS = 'pharmacy_filter_orders_by_status';

// Description: Leverage the new capability of multiple product images to show carousels on the product catalogue pages
// Default On Variation: true
// Default Off Variation: false
export const FF_PRODUCT_IMAGE_CAROUSEL = 'product_image_carousel';

// Description: Updated patient order statuses to reduce confusion. This feature flag only impacts what patients see in the patient portal, it does
// not impact the granular statuses that the Montu team sees. --> https://montugroup.atlassian.net/browse/FX-266
// Default On Variation: true
// Default Off Variation: false
export const FF_UPDATED_PATIENT_ORDER_STATUSES = 'UPDATED_PATIENT_ORDER_STATUSES';

// Description: This is to allow us to toggle the visibility of the word of mouth campaign that allows us to generate referral codes
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_WOM_CAMPAIGN = 'enable_wom_campaign';

// Description: When enabled, patients who have made orders in the past two weeks that have been shipped by pharmacies will see this component
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION = 'enable_order_tracking_status_notification';

// Description: Enable super admins to adjust inventory.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SUPER_ADMIN_INVENTORY = 'enable_super_admin_inventory';

// Description: enables patient profile merges for super admins
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_PROFILE_MERGE = 'enable_patient_profile_merge';

// Description: Toggles the visibility of the order assignment tool until ready for production use
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_ASSIGNMENT_TOOL = 'enable_order_assignment_tool';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_DISCOUNTS = 'enable_shopify_discounts';

// Description: When enabled, FE uses a new BE endpoint which returns products and inventory for a pharmacy including inactive products instead of
// only active products.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS = 'enable_pharmacy_inventory_inactive_products';

// Description: This feature flag enables or disables the display of a notice message on the modal. When enabled, the content of the Word of Mouth
// (WOM) popup is replaced with a notice message.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_NOTICE_ON_MODAL = 'enable_notice_on_modal';

// Description: FF to control rollout of google places autocomplete for Circuit
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE = 'enable_google_places_autocomplete';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE = 'enable_google_places_autocomplete_pt_profile';

// Description: The FF will enable/disable features related to replacement orders functionality in FE.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY = 'enable_replacement_orders_in_shopify';

// Description: A flag to toggle banner in circuit frontend.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SAFESCRIPT_OUTAGE_BANNER = 'enable_safescript_outage_banner';

// Description: This feature flag enables the client to filter out inactive pharmacies when searching through trusted partner pharmacies.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_INACTIVE_PHARMACY_FILTER = 'enable_inactive_pharmacy_filter';

// Description: Show product description and usage sections for my treatment plan
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE = 'show_product_description_and_usage';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_FILTER_BY_PHARMACY = 'enable_order_filter_by_pharmacy';

// Description: Hide Add Credit button
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_ADMIN_CREDIT_PROVISION = 'disable_admin_credit_provision';

// Description: Shows credit deprecation warnings banner and text UI changes
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_CREDIT_DEPRECATION_WARNINGS = 'patient_credit_deprecation_warnings';

// Description: Is the link url string used on the credit page banner
// Default On Variation: https://support.montu.com.au/hc/en-au/articles/34907935744153-How-are-credits-and-discounts-changing
// Default Off Variation: https://support.montu.com.au/hc/en-au/articles/34907935744153-How-are-credits-and-discounts-changing
export const FF_CREDIT_CHANGES_BANNER_LINK = 'credit_changes_banner_link';

// Description: Replacing product catalog with only prescribed product information for compliance. https://montugroup.atlassian.net/browse/UMED-69
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_MY_TREATMENT_PLAN = 'enable_my_treatment_plan';

// Description: Show the patient refill banner regarding Shopify checkout
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_REFILL_CHECKOUT_BANNER = 'patient_refill_checkout_banner';

// Description: link url string used on the patient refill page banner regarding checkout changes
// Default On Variation: https://support.montu.com.au/hc/en-au/articles/34912569221657-How-has-the-checkout-process-changed
// Default Off Variation: https://support.montu.com.au/hc/en-au/articles/34912569221657-How-has-the-checkout-process-changed
export const FF_REFILL_CHECKOUT_CHANGES_BANNER_LINK = 'refill_checkout_changes_banner_link';

// Description: Send query params to shopify discount app via circuit modal
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS = 'shopify_discount_modal_query_params';

// Description: When enabled, admin discount creation button is hidden
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_ADMIN_DISCOUNT_CREATION = 'disable_admin_discount_creation';

// Default On Variation: false
// Default Off Variation: false
export const FF_ORDERMEDICATIONPAGE2024 = 'OrderMedicationPage2024';

// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISCOUNT_SUPER_ADMINS = 'shopify_discount_super_admins';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_404_AND_500_ERROR_PAGES = 'enable_404_and_500_error_pages';

// Description: Enables the patients orders page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_ORDERS_PAGE = 'enable_patient_orders_page';

// Description: Enables the patients orders white label images
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES = 'enable_patient_orders_white_label_images';

// Description: This is a feature flag for the auto complete address on the new Profile Page. It is separate to other autocomplete feature flags as it
// is using a new component being built in the design system
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_UMEDS = 'enable_google_places_umeds';

// Description: Redirect to new shopify thank you order summary.
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE = 'shopify_redirect_thank_you_page';

// Description: Enables Snowplow Clickstream tracking in Circuit
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SNOWPLOW_CIRCUIT = 'enable_snowplow_circuit';

// Description: https://montugroup.atlassian.net/browse/B2CS-1235
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT = 'patient_discharge_circuit_checkout_disabled_alert';

// Description: Enable umeds checkbox on Pharmacy details page
// Default On Variation: true
// Default Off Variation: false
export const FF_FF_ENABLE_UMEDS_CHECKBOX = 'FF_ENABLE_UMEDS_CHECKBOX';

// Description: The new umeds rebrand wants to keep montu brand as the partner for the initial phases.
// Default On Variation: true
// Default Off Variation: false
export const FF_POWERED_BY_UMEDS = 'powered_by_umeds';

// Description: patient_refill_checkout_banner text
// Default On Variation: Please note: vaporiser devices (that are not ARTG listed) and batteries are now no longer available due to a change in legislation. If impacted, book a follow-up appointment with your doctor to discuss alternatives.
// Default Off Variation: Please note: vaporiser devices (that are not ARTG listed) and batteries are now no longer available due to a change in legislation. If impacted, book a follow-up appointment with your doctor to discuss alternatives.
export const FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT = 'patient_refill_checkout_banner_text';

// Description: Browsing of product catalog by pharmacists
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_PRODUCT_CATALOG = 'pharmacist_product_catalog';

// Description: Word of mouth related price control
// Default On Variation: 40
// Default Off Variation: 20
export const FF_WOM_PRICE = 'wom_price';

// Description: Enables the brand theme navigation for uMeds/Montu
// Default On Variation: true
// Default Off Variation: false
export const FF_BRAND_THEME_NAV = 'brand_theme_nav';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PREFILL_PRODUCT_QUANTITY = 'enable_prefill_product_quantity';

// Description: Controls the display of WOM campaign terms and conditions copy
// Default On Variation: Offer is only valid for participants who are new customers and who book their initial Alternaleaf nurse consultation by 31 January 2025.
// Default Off Variation: Offer is only valid for participants who are new customers.
export const FF_WOM_TNC_COPY = 'wom_tnc_copy';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_UPDATE_HISTORY_TS = 'enable_pharmacy_update_history_ts';

// Description: Enables/Disables the Recommended Devices section of the Order Medication page in Circuit
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_RECOMMENDED_DEVICES = 'enable_recommended_devices';

// Default On Variation: true
// Default Off Variation: false
export const FF_UMEDS_REBRAND_UNAUTHENTICATED_ROUTES = 'umeds_rebrand_unauthenticated_routes';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_VIEW_ALL_PATIENTS_TS_MIGRATION = 'enable_view_all_patients_ts_migration';

// Description: Enables component on pharmacy settings page that allows adding shippit API key
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ADD_SHIPPIT_TOKEN = 'ENABLE_ADD_SHIPPIT_TOKEN';

// Description: This enables redirection to the patient page after creating a new admin order
// Default On Variation: true
// Default Off Variation: false
export const FF_ADMIN_ORDER_CREATION_REDIRECT_TO_PATIENT_PAGE = 'admin_order_creation_redirect_to_patient_page';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_DISCOUNT_MODAL_TS_MIGRATION = 'enable-patient-discount-modal-ts-migration';

// Description: Enabling this flag will remove the logging out of users who hit a route that they shouldn't have hit. eg: admins trying to hit a
// super-admin route accidentally
// Default On Variation: true
// Default Off Variation: false
export const FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION = 'prevent_logout_on_unauthed_navigation';

// Description: Enabling this flag will enable the zustand auth token store to persist the token in local storage (but not use it for anything)
// Default On Variation: true
// Default Off Variation: false
export const FF_ZUSTAND_AUTH_STORE_SET_TOKEN = 'zustand_auth_store_set_token';

// Description: Enable pharmacy view typescript component
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_VIEW_TS_MIGRATION = 'enable_pharmacy_view_ts_migration';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDERS_TS_MIGRATION = 'enable_orders_ts_migration';

// Description: Log the error within the error boundary to data dog
// Default On Variation: true
// Default Off Variation: false
export const FF_CIRCUIT_FRONTEND_ERRORBOUNDARY_LOG = 'circuit_frontend_errorboundary_log';

// Description: Enabling Pharmacy Report Component for TS migration
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_REPORT_TS_COMPONENT = 'enable_pharmacy_report_ts_component';

// Description: Enables the use of the BroadcastChannel API for local SessionStorage synchronisation.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_BROADCAST_CHANNEL_API = 'enable-broadcast-channel-api';

// Description: https://montugroup.atlassian.net/browse/UMED-858
// Default On Variation: true
// Default Off Variation: false
export const FF_ONLY_ALLOW_FULL_ORDER_ORDER_DISCOUNTS_FOR_ADMIN_ORDERS =
  'only-allow-full-order-order-discounts-for-admin-orders';

// Description: Enables useCustomer hook (which makes API requests and drives isDischarged UI components initially)
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_CUSTOMER_HOOK = 'use-customer-hook';

// Description: Limit the pagination size of the pharmacists order table to lte 50
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_ORDERS_TABLE_SMALL_SIZE = 'pharmacist_orders_table_small_size';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_DOCTORS_TS_MIGRATION = 'enable_doctors_ts_migration';

// Description: Enable eScript upload prototype features and pages.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ESCRIPT_PROTOTYPE = 'enable_escript_prototype';

// Description: Enable the SAS Approval Alert/Banner, and the checkbox on the checkout page
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SAS_APPROVAL = 'enable_sas_approval';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_ALLOCATION_TOOL_V2 = 'enable-order-allocation-tool-v2';

// Description: Updates the req body to match the createPharmacy in typescript
// Default On Variation: true
// Default Off Variation: false
export const FF_CREATE_PHARMACY_TYPESCRIPT = 'create_pharmacy_typescript';

// Description: Enable to display concession discount banner/alert on profile and order pages
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_CONCESSION_DISCOUNT_BANNER = 'enable_concession_discount_banner';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_PROFILE_UPLIFT = 'enable_patient_profile_uplift';

// Description: Align the order summary component between checkout pages
// Default On Variation: true
// Default Off Variation: false
export const FF_ALIGN_PHARMACY_ORDER_SUMMARY = 'align-pharmacy-order-summary';

// Description: enable to display the concession awareness related text and discounts on the checkout
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_CONCESSION_AWARENESS_CHECKOUT = 'enable_concession_awareness_checkout';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_FILTER_SELECTS_REDESIGN = 'enable_filter_selects_redesign';

// Description: When enabled, the full product name will be displayed instead of the short_name wherever product information is rendered.
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_FULL_PRODUCT_NAMES = 'use_full_product_names';

// Description: Toggles the updated WOM discount copy for increased discount visibility
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_WOM_DISCOUNT_INCREASED_COPY = 'enable_wom_discount_increased_copy';

// Default On Variation: [object Object]
// Default Off Variation: [object Object]
export const FF_CREATE_ORDER_CTA_EXPERIMENT = 'create_order_cta_experiment';

// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_INTERVAL_TIME = 'order_interval_time';

// Description: This prevents a flash of an authenticated screen if we are about to log the user out anyway
// Default On Variation: true
// Default Off Variation: false
export const FF_AUTO_LOGOUT_ON_EXPIRED_TOKEN_REQUEST = 'auto-logout-on-expired-token-request';

// Description: Show the sub-brand banner on the admin-portal [Does not affect circuit]
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOW_ADMIN_PORTAL_SUB_BRANDING = 'show-admin-portal-sub-branding';

// Description: Enables pharmacists/admins to view batch dispensed orders
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_BATCH_DISPENSE_VIEW = 'enable_batch_dispense_view';

// Description: if ON, redirects users to the admin portal login page
// Default On Variation: true
// Default Off Variation: false
export const FF_DSPLIT_ADMIN_PORTAL_REDIRECT = 'dsplit_admin_portal_redirect';

// Description: if ON, will redirect user to either patient portal (Alternaleaf) or customer portal (uMeds) depending on whether user is intending to
// sign in to Alternaleaf or uMeds
// Default On Variation: true
// Default Off Variation: false
export const FF_DSPLIT_PATIENT_CUSTOMER_PORTAL_REDIRECT = 'dsplit_patient_customer_portal_redirect';

// Description: if ON, redirects user to pharmacist portal
// Default On Variation: true
// Default Off Variation: false
export const FF_DSPLIT_PHARMACIST_PORTAL_REDIRECT = 'dsplit_pharmacist_portal_redirect';

// Description: if ON, redirects user to appropriate portal even if they aren't signed in. Directs to same path on the portal that they were trying to
// access on circuit.
// Default On Variation: true
// Default Off Variation: false
export const FF_DSPLIT_REDIRECT_ALL = 'dsplit_redirect_all';

// Description: Enable patient list as per new design
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_LIST = 'enable_patient_list';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_DETAILS_UPLIFT = 'enable_order_details_uplift';

// Description: https://montugroup.atlassian.net/browse/B2C-5680
// Default On Variation: true
// Default Off Variation: false
export const FF_CALCOM_EMBED_PARAMS = 'calcom_embed_params';

// Description: Disable Export and Ship all button in order list
// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_LIST_DISABLE_EXPORT_AND_SHIP_BUTTON = 'order_list_disable_export_and_ship_button';

// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER = 'order';

// Description: Disable Export button from order list
// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_LIST_DISABLE_EXPORT_BUTTON = 'order_list_disable_export_button';

// Description: Add checkbox behind FF
// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_LIST_DISABLE_CHECKBOX = 'order_list_disable_checkbox';

// Description: enables the admin portal redirection banner if a date exists, also uses the given date in the banner text
// Default On Variation: 13/02/25
// Default Off Variation:
export const FF_ADMIN_PORTAL_REDIRECT_BANNER_DATE = 'admin-portal-redirect-banner-date';

// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_DISPENSE_PRIORITY = 'disable_dispense_priority';

// Description: Enables the eScript Order Medication and eScript Pharmacy Allocation pages
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ESCRIPT_ORDERS = 'enable_escript_orders';

// Description: Allow navigating between customer & patient portals to pass auth state and not require a re-login
// Default On Variation: true
// Default Off Variation: false
export const FF_CUSTOMER_PATIENT_PORTALS_AUTH_TRANSFER_NAVIGATIONS =
  'customer-patient-portals-auth-transfer-navigations';

// Description: enables the Pharmacy portal redirection banner if a date exists, also uses the given date in the banner text
// Default On Variation: 20/02/2025
// Default Off Variation:
export const FF_PHARMACY_PORTAL_REDIRECT_BANNER_DATE = 'pharmacy_portal_redirect_banner_date';

// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLED_ORDER_DISCOUNT_BUTTON = 'disabled_order_discount_button';

// Description: When enabled, Circuit frontend will redirect patient to tasmanian doctor followup if they have a tasmanian address
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_TASMANIAN_DOCTOR_FOLLOW_UP = 'enable_tasmanian_doctor_follow_up';

// Description: Enables clickstream tracking for Checkboxes via the DesignSystem
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_CHECKBOX_CLICKSTREAM = 'enable_checkbox_clickstream';

// Default On Variation: false
// Default Off Variation: false
export const FF_ENABLE_NEW_USER_AGREEMENTS_ENDPOINT = 'enable-new-user-agreements-endpoint';

// Description: Enables the Interval/Dispense limit messaging on the product cards on the order medication page; Also controls which of the 2 variants
// is in use.
// Default On Variation: A
// Default Off Variation: Control
export const FF_ENABLE_ORDER_DISPENSE_LIMIT_MESSAGE = 'enable_order_dispense_limit_message';

// Description: Enables the new version of the patient list that includes updates for the escript upload project
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_LIST_V2 = 'enable_patient_list_v2';

// Description: Enable discount code generation using the newer circuit-service endpoint that generates discount codes in Shopify.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_DISCOUNT_GENERATION = 'enable_shopify_discount_generation';

// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_PRODUCT_SELECTIONS_ON_DOCTOR_PROFILE = 'disable_product_selections_on_doctor_profile';

// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_EDIT_ACCESS_ON_GP_PROFILE_PAGE = 'disable_edit_access_on_gp_profile_page';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_ORDER_PAYMENT_RECEIPTS = 'enable_pharmacy_order_payment_receipts';
