import { jsx as l } from "@emotion/react/jsx-runtime";
import b from "@mui/material/Checkbox";
import { createContext as C, useContext as d, useMemo as v } from "react";
const u = "__NOT_APPLICABLE__", _ = () => u, k = (r) => r.target.value, h = (r, t, o, e, a) => o ? (n, ...s) => {
  const E = a ? a(n) : u;
  return e(r, t, E, n), o(n, ...s);
} : (n) => {
  const s = a ? a(n) : u;
  e(r, t, s, n);
}, i = (r, t, o, e, a) => o.reduce(
  (n, s) => (n[s] = h(t, s, e[s], r, a), n),
  {}
), x = () => {
}, c = C({
  baseEventHandler: x
}), B = (r) => {
  const { handler: t, children: o } = r, e = {
    baseEventHandler: t
  };
  return /* @__PURE__ */ l(c.Provider, { value: e, children: o });
}, H = () => d(c), P = (r, t, o, e) => {
  const { baseEventHandler: a } = d(c), n = v(() => i(a, r, t, o, e), [r, t, o, e]);
  return {
    ...o,
    ...n
  };
}, g = "Checkbox", p = ["onChange"], G = (r) => {
  const t = P(g, p, r, _);
  return /* @__PURE__ */ l(b, { ...t });
};
G.displayName = "Checkbox";
export {
  G as C,
  c as G,
  k as I,
  _ as N,
  B as a,
  P as b,
  H as u
};
