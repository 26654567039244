import { jsxs as s, jsx as i } from "@emotion/react/jsx-runtime";
import { styled as d, Box as t, Divider as o } from "@mui/material";
const a = d(t)(({ theme: e }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingInline: e.spacing(3)
})), h = ({ logo: e, sx: r = [] }) => /* @__PURE__ */ s(t, { width: "100%", height: { xs: "3.5rem", md: "5rem" }, children: [
  /* @__PURE__ */ i(a, { sx: [...Array.isArray(r) ? r : [r]], children: e }),
  /* @__PURE__ */ i(
    o,
    {
      sx: (n) => ({
        [n.breakpoints.down("md")]: {
          display: "none"
        }
      })
    }
  )
] });
export {
  h as H
};
