import React, { createContext, useMemo, useState } from 'react';

export interface PreferencesData {
  umedsMarketingConsent: boolean | undefined;
  setUmedsMarketingConsent: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  umedsTermsAndConditionsAccepted: boolean | undefined;
  setUmedsTermsAndConditionsAccepted: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const PreferencesContext = createContext<PreferencesData>({
  umedsMarketingConsent: false,
  setUmedsMarketingConsent: () => null,
  umedsTermsAndConditionsAccepted: false,
  setUmedsTermsAndConditionsAccepted: () => null
});

export function PreferencesProvider({ children }: { children: React.ReactNode }) {
  const [umedsMarketingConsent, setUmedsMarketingConsent] = useState<boolean>();
  const [umedsTermsAndConditionsAccepted, setUmedsTermsAndConditionsAccepted] = useState<boolean>();

  const value: PreferencesData = useMemo(
    () => ({ umedsMarketingConsent, setUmedsMarketingConsent, umedsTermsAndConditionsAccepted, setUmedsTermsAndConditionsAccepted }),
    [umedsMarketingConsent, umedsTermsAndConditionsAccepted]
  );

  return <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>;
}
