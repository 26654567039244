import { AdminPageSection } from '@/components/layout/AdminPageSection';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { SkippedProductOption } from '@/hooks/order/useOrderFormProducts';
import { Table } from '@montugroup/design-system';
import type { ColumnDef } from '@tanstack/react-table';

export function SkippedProductsTable() {
  const { skippedProducts } = useOrderFormMeta();

  if (skippedProducts.length === 0) {
    return null;
  }

  const columns: ColumnDef<SkippedProductOption>[] = [
    {
      accessorKey: 'name',
      header: 'Product',
      enableSorting: false
    },
    {
      accessorKey: 'reason',
      header: 'Reason',
      enableSorting: false
    }
  ];

  return (
    <AdminPageSection title="Skipped Products">
      <Table columns={columns} data={skippedProducts} elevation={0} hasRowBackgroundColor={false} />
    </AdminPageSection>
  );
}
