import axios, { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { FF_ENABLE_NEW_USER_AGREEMENTS_ENDPOINT } from '@/constants/featureFlags';
import useFeatureFlags from '../useFeatureFlags';
import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

import { AgreementType, type RequestError, type UserAgreement } from './types';

const logger = new Logger('useGetUserAgreement');

export function useGetUserAgreements() {
  const [agreements, setAgreements] = useState<UserAgreement[]>([]);
  const [error, setError] = useState<RequestError | null>(null);
  const [loading, setLoadingState] = useState(false);
  const { flags } = useFeatureFlags();
  const useNewAgreements = flags[FF_ENABLE_NEW_USER_AGREEMENTS_ENDPOINT];

  const makeRequest = useCallback(async () => {
    setLoadingState(true);
    try {
      const endpoint = useNewAgreements
        ? `${settings.url}/user/agreements`
        : `${settings.url}/user/agreement`;
        
      const response = await axios.get(endpoint);
      
      if (useNewAgreements) {
        setAgreements(response.data);
      } else {
        // todo: clean up once the old endpoint is removed
        // this code is failsafe for out of order deployments. This will be cleaned up once 
        // deployments are complete
        // For the old endpoint, we convert the response to the new format
          setAgreements([
            { ...response.data, agreementType: AgreementType.PrivacyPolicy },
          ]);
      }
      setError(null);
    } catch (err) {
      if (isAxiosError(err) && err?.response?.status === 404) {
        setAgreements([]);
        return;
      }
      logger.error(err);
      setError(err as RequestError);
    } finally {
      setLoadingState(false);
    }
  }, [useNewAgreements]);

  useEffect(() => {
    if (agreements.length === 0 && !loading && !error) {
      makeRequest();
    }
  }, [agreements.length, loading, error, makeRequest]);

  const hasPrivacyPolicyAgreement = agreements.some((agreement) => agreement.agreementType === AgreementType.PrivacyPolicy);
  const hasTermsAndConditionsAgreement = agreements.some((agreement) => agreement.agreementType === AgreementType.TermsAndConditions);

  return {
    error,
    hasPrivacyPolicyAgreement,
    hasTermsAndConditionsAgreement,
    loading,
    setAgreements
  };
}

export default useGetUserAgreements;
