import { ProcessPaymentButton } from '@/components/order/OrderActions/ProcessPaymentButton';
import { SendPatientInvoiceButton } from '@/components/order/OrderActions/SendPatientInvoiceButton';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export function OrderActions() {
  const [sendInvoiceStatus, setSendInvoiceStatus] = useState('idle');
  const {
    formState: { isDirty, isLoading, isSubmitting, isValidating }
  } = useFormContext();

  const shouldDisableButtons = isDirty || isLoading || isSubmitting || isValidating;

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <SendPatientInvoiceButton
        disabled={sendInvoiceStatus !== 'idle' || shouldDisableButtons}
        onSendInvoiceStatusChange={setSendInvoiceStatus}
      />
      <ProcessPaymentButton disabled={sendInvoiceStatus !== 'idle' || shouldDisableButtons} />
    </Stack>
  );
}
