import { toast } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import LoginFailure from '@/components/auth/login/components/LoginFailure';
import FormInput from '@/components/common/FormInputTyped';
import { IcebergPapayaContained, PapayaContained } from '@/components/common/Papaya';
import PatientLoginModal from '@/components/patientReferral/patientLoginModal';
import { EMAIL_PATTERN } from '@/constants/constants';
import { FF_ENABLE_WOM_CAMPAIGN } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { SignInFormData } from '@/pages/auth/SignIn';
import { AuthService } from '@/services/authentication.service';
import { migrateDiscountsForPatient } from '@/services/discountMigration.service';
import { UserService } from '@/services/user.service';
import { alternaleafTheme } from '@/theme';
import { LoadingButton } from '@/ui-library';
import { Logger } from '@/utils/logger';

import logo from '../../layout/alternaleaf.svg';
import {
  AuthCardContainer,
  AuthFormGroup,
  AuthPageContainer,
  CardHalfContent,
  CardHalfDecoration,
  FormContainer,
  MobileLogoContainer
} from '../authPage';

import usePageMetadata from '@/hooks/usePageMetadata';
import { BrandThemeProvider } from '@montugroup/themes';
import { OTHER_LOGINS } from './types';

const logger = new Logger('PatientLogInForm');

const AuthCardBackground = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  /* prettier-ignore */
  backgroundImage: 'url(\'/assets/images/patientBack_AL.png\')',
  backgroundPosition: 'top left',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  padding: '2rem',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px'
}));

const MobileOnly = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

interface PatientLoginProps {
  onBoardKey: string | null;
}

const otherLogins = OTHER_LOGINS.get('patient') || [];
const contactLink = 'https://www.alternaleaf.com.au/contact-us';

const pageTitle = 'Log in to your Alternaleaf patient portal';
const pageDescription =
  'Refill your prescription or book a follow-up appointment with your doctor to discuss or make changes to your treatment plan.';

function PatientLogin(props: PatientLoginProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { onBoardKey } = props;

  usePageMetadata({
    title: pageTitle,
    'og:title': pageTitle,
    description: pageDescription,
    'og:description': pageDescription
  });

  const { register, handleSubmit, formState, setValue } = useForm<SignInFormData>({
    defaultValues: {
      email: '',
      password: '',
      rePassword: ''
    }
  });
  const { errors } = formState;
  const [submissionError, setSubmissionError] = useState('');
  const { flags } = useFeatureFlags();
  const [isOnboarding, setIsOnboarding] = React.useState<boolean>(onBoardKey !== null);
  const [loading, setLoad] = React.useState(false);

  React.useLayoutEffect(() => {
    const loggedIn = AuthService.getUser();
    if (
      loggedIn &&
      loggedIn.user &&
      loggedIn.user.role_id === USER_ROLES.patient &&
      (matchPath({ path: '/' }, location.pathname) ||
        matchPath({ path: '/login' }, location.pathname) ||
        matchPath({ path: '/login/patient' }, location.pathname))
    ) {
      navigate('/home', { replace: true });
    }
  }, [location.pathname, navigate]);

  React.useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.get('expired')) {
      toast.error('Your session has expired, please login again.', {});
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      if (isOnboarding && onBoardKey) {
        try {
          const { data } = await UserService.getUserOnboardEmail(onBoardKey);
          if (!data) {
            toast.warn('Invalid key. Please try again!');
            navigate('/logout');
            return;
          }
          if (data.expired) {
            toast.warn('The link has expired. Please reset your password.');
            navigate('/forgot-password/patient');
            return;
          }

          setValue('email', data.email);
        } catch {
          toast.error('Unexpected error. Please retry later.');
          navigate('/login/patient');
        }
      }
    })();
  }, [onBoardKey, isOnboarding]);

  const onRegister: SubmitHandler<SignInFormData> = async (formData, e) => {
    e?.preventDefault();
    setLoad(true);
    try {
      const user = await UserService.registerUserPassword({
        token: onBoardKey,
        password: formData.password
      });
      if (user) {
        setIsOnboarding(false);
        setValue('password', '');
        setValue('rePassword', '');
        setLoad(false);
        toast.success('Registered Successfully. Please Login now.', {});
        navigate('/login/patient', { replace: true });
      }
    } catch (err) {
      toast.error('Error Occurred!. Try again later!', {});
      logger.error('handleRegister', err);
      setLoad(false);
    }
  };

  const onSubmit: SubmitHandler<SignInFormData> = async (formData, e) => {
    e?.preventDefault();

    setSubmissionError('');
    setLoad(true);

    const userFrom = location?.state?.from?.pathname || '';
    const userFromState = location?.state?.from?.state || {};
    let user;

    try {
      user = await AuthService.login(formData.email.toLowerCase().trim(), formData.password, false, 'patient', '');
    } catch (err) {
      logger.info('AuthService.login failed: ', err);
      setSubmissionError(err as string);
      setLoad(false);
    }

    if (user) {
      if (user.user?.role_id === USER_ROLES.patient) {
        if (userFrom && !userFrom.includes('logout')) {
          logger.info('navigating to patient: ', userFrom);
          navigate(
            {
              pathname: userFrom
            },
            {
              state: userFromState,
              replace: true
            }
          );
        } else {
          logger.info('navigating to patient /home');

          // Before a patient places an order, we want to make sure
          // all their outstanding discounts are migrated to Shopify.
          // Method fails silently and doesn't throw any errors. We only
          // fire and forget as we do not want to interrupt user flow if it fails.
          migrateDiscountsForPatient();

          navigate('/home', {
            replace: true
          });
        }
        return;
      }
      if (user.user?.phone === '') {
        logger.info('navigating to profile detail');
        navigate('/profile-detail', {
          replace: true
        });
        toast.success('Complete your profile details');
        return;
      }
      if (userFrom && !userFrom.includes('logout')) {
        logger.info('navigating to: ', userFrom);
        navigate(
          {
            pathname: userFrom
          },
          {
            replace: true,
            state: userFromState
          }
        );
      } else {
        logger.info('navigating to patient /orders');
        navigate('/orders', {
          replace: true
        });
      }
    }
  };

  return (
    <BrandThemeProvider theme={alternaleafTheme}>
      <Box width="100%" height="100%" position="relative">
        <AuthPageContainer>
          <AuthCardContainer>
            <CardHalfDecoration>
              <AuthCardBackground>
                <PapayaContained />
              </AuthCardBackground>
            </CardHalfDecoration>
            <CardHalfContent>
              <MobileLogoContainer>
                <a href="https://www.montu.com.au" target="blank">
                  <img src={logo} alt="Alternaleaf" className="px-4 py-2" />
                </a>
              </MobileLogoContainer>
              <FormContainer>
                <LoginFailure error={submissionError} showSupportText />
                <form data-private method="post">
                  <h2 className="mb-4">
                    {onBoardKey ? 'Set a password for your account' : 'Log in to your patient portal'}
                  </h2>
                  <AuthFormGroup>
                    <FormInput
                      {...register('email', {
                        required: 'You must enter an email address',
                        pattern: {
                          value: EMAIL_PATTERN,
                          message: 'You must enter a valid email address'
                        }
                      })}
                      type="text"
                      id="useremailone"
                      error={errors.email?.message}
                      autoComplete="email"
                      disabled={!!onBoardKey}
                      placeholder="Your Email Address"
                      label={onBoardKey ? 'Your email' : 'Patient Login'}
                    />
                    <FormInput
                      {...register('password', {
                        required: 'You must enter a password',
                        minLength: {
                          value: 5,
                          message: 'Password must be at least 5 characters'
                        }
                      })}
                      type="password"
                      id="userpasswordone"
                      error={errors.password?.message}
                      placeholder="Enter password"
                      label={onBoardKey ? 'Enter new password' : 'Password'}
                    />

                    {onBoardKey && (
                      <FormInput
                        {...register('rePassword', {
                          required: true,
                          validate: (val, formData) => val === formData.password
                        })}
                        type="password"
                        id="re-password"
                        autoComplete="off"
                        label="Confirm new password"
                        placeholder="Enter password"
                        error={errors.rePassword?.message}
                      />
                    )}
                  </AuthFormGroup>

                  <div className="d-flex flex-wrap justify-content-between mb-4">
                    <Link to="/forgot-password/patient">Forgot Password?</Link>
                  </div>

                  <LoadingButton
                    type="submit"
                    id="btnSubmit"
                    variant="contained"
                    color="primary"
                    className="w-100"
                    loading={loading}
                    data-testid="login-button"
                    onClick={onBoardKey ? handleSubmit(onRegister) : handleSubmit(onSubmit)}
                  >
                    {onBoardKey ? 'Set password' : 'Login'}
                  </LoadingButton>
                </form>
                <div className="d-flex flex-wrap text-center">
                  <div className="d-flex mt-4 mx-auto text-center">
                    {otherLogins.map((x, idx) => (
                      <div key={x}>
                        <Link className="mx-1" to={`/login/${x}`}>
                          Login as <span style={{ textTransform: 'capitalize' }}>{x}</span>
                        </Link>
                        {otherLogins.length !== idx + 1 && '/'}
                      </div>
                    ))}
                  </div>
                </div>

                <Box marginTop="1.5rem" display="flex" justifyContent="center">
                  <span>
                    Do you need help?&nbsp;
                    <a href={contactLink}>Contact us</a>
                  </span>
                </Box>
                {flags[FF_ENABLE_WOM_CAMPAIGN] && <PatientLoginModal />}
              </FormContainer>
            </CardHalfContent>
          </AuthCardContainer>
          <MobileOnly>
            <IcebergPapayaContained sx={{ position: 'fixed' }} />
          </MobileOnly>
        </AuthPageContainer>
      </Box>
    </BrandThemeProvider>
  );
}

export default PatientLogin;
