import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import AuthService from '@/services/authentication.service';
import { Button, toast } from '@montugroup/design-system';
import PaymentIcon from '@mui/icons-material/Payment';
import { Box, Tooltip } from '@mui/material';

type CollectPaymentButtonProps = {
  disabled: boolean;
};

export function ProcessPaymentButton({ disabled }: CollectPaymentButtonProps) {
  const { order, isAdminOrderInAuthorisedPaymentStatus } = useOrderFormMeta();

  const hasInvoiceUrl = order?.orderManagementShopify?.shopify_draft_order_invoice_url;
  const isUnpaidOrder = order?.payment_status !== 'PAID';

  const handleClick = () => {
    if (order?.orderManagementShopify?.shopify_draft_order_invoice_url) {
      window.open(order.orderManagementShopify.shopify_draft_order_invoice_url);
    } else {
      toast.error('Invoice URL could not be found');
    }
  };

  if (!AuthService.isAdmin() || !hasInvoiceUrl || !isUnpaidOrder || !isAdminOrderInAuthorisedPaymentStatus) {
    return null;
  }

  return (
    <Button
      variant="contained"
      disabled={disabled || order?.paymentGateway === 'SHOPIFY_BANK_TRANSFER'}
      size="large"
      startIcon={<PaymentIcon fontSize="small" />}
      onClick={handleClick}
    >
      <Box sx={{ position: 'relative', top: '1.5px' }}>
        {order?.paymentGateway === 'SHOPIFY_BANK_TRANSFER' ? (
          <Tooltip
            arrow
            title="The patient selected the bank deposit payment option."
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10]
                    }
                  }
                ]
              }
            }}
          >
            <span>Process Payment</span>
          </Tooltip>
        ) : (
          'Process Payment'
        )}
      </Box>
    </Button>
  );
}
