import useGetProductsFromInventory from '@/hooks/order/useGetProductsFromInventory';
import type { PatientForOrderDetails } from '@/services/order.service';
import type { Order, OrderProduct, Product } from '@/types';
import {
  createOrderProductOption,
  EMPTY_PRODUCT_OPTION,
  getAvailability,
  getErrorMessage
} from '@/utils/orderFormProductUtils';
import { toast } from '@montugroup/design-system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export type OrderProductOptionAvailability = {
  repeats: number | null;
  intervalEndDate: string | null;
  baseQuantity?: number;
  dispensedDate?: string | null;
};

export type OrderProductOption = OrderProductOptionAvailability & {
  productId: number | null;
  productName?: string;
  remainingUnits?: number;
  quantity: number | null;
  shippedDate?: string;
  doctorName: string | null;
  prescriptionCode: string | null;
  interval?: number;
  lastDispensedLocation?: string;
  supplierName: string;
  error?: boolean;
  subLabel?: string;
  isDisabled?: boolean;
  formulationId: number | null;
};

export type SkippedProductOption = {
  id: number;
  name: string;
  reason: string;
};

export const useOrderFormProducts = (order: Order | undefined, patientOrder: PatientForOrderDetails | undefined) => {
  const { refill } = useParams();
  const [selectedProducts, setSelectedProducts] = useState<OrderProductOption[]>([EMPTY_PRODUCT_OPTION]);
  const { data: inventory, isLoading: inventoryIsLoading } = useGetProductsFromInventory(
    order?.pharmacy_id ?? patientOrder?.patient?.pharmacy_id
  );
  const [skippedProducts, setSkippedProducts] = useState<SkippedProductOption[]>([]);
  const [availableProducts, setAvailableProducts] = useState<OrderProductOption[]>([]);

  useEffect(() => {
    if (!order && !patientOrder) {
      return;
    }
    const newSkippedRows: SkippedProductOption[] = [];
    const newRows: OrderProductOption[] = [];

    const handleSkip = (productId: number, productName: string, reason: string, toastMessage: string) => {
      newSkippedRows.push({ id: productId, name: productName, reason });
      toast.warn(toastMessage);
    };

    const processProduct = (orderProduct: OrderProduct, product: Product | undefined) => {
      if (!product) {
        handleSkip(
          orderProduct.product_id,
          orderProduct.Product.name,
          'Inactive',
          `Skipped ${orderProduct.Product.name} as it is inactive`
        );
        return;
      }

      if (product.remaining_units === 0) {
        handleSkip(
          orderProduct.product_id,
          orderProduct.Product.name,
          'Out of Stock',
          `Skipped ${orderProduct.Product.name} as it is out of stock`
        );
        return;
      }

      if (product.reasoning_toggle) {
        handleSkip(
          orderProduct.product_id,
          orderProduct.Product.name,
          'Not Available',
          `Skipped ${orderProduct.Product.name} as it is not available for the ${
            order?.Patient.client_id ? 'Patient' : 'Doctor'
          }`
        );
        return;
      }

      if (!!refill && (!product || product.remaining_units === 0 || product.reasoning_toggle)) {
        return;
      }

      let quantity = orderProduct.quantity || product.quantity || product.quantity_base_order;

      if (product.formulation_id !== 5 && product.remaining_units) {
        quantity = quantity
          ? Math.min(quantity, product.remaining_units)
          : Math.min(product.quantity_base_order, product.remaining_units);
      }

      const availability = getAvailability(product, quantity, order?.dispensedDate, order?.dispensed_date);
      newRows.push(createOrderProductOption(product, quantity, availability, orderProduct, order));
    };

    order?.OrderProducts?.forEach((orderProduct: OrderProduct) => {
      const product = order?.products.find((p: Product) => p.id === orderProduct.product_id);
      processProduct(orderProduct, product);
    });

    if (patientOrder) {
      newRows.push(EMPTY_PRODUCT_OPTION);
    }

    setSelectedProducts(newRows.sort((a, b) => a.supplierName.localeCompare(b.supplierName)));
    setSkippedProducts(newSkippedRows);
  }, [order, patientOrder, refill]);

  useEffect(() => {
    if (inventoryIsLoading || !inventory) {
      return;
    }
    const patientDetails = order?.Patient ?? patientOrder?.patient;
    const products = (order?.products ?? patientOrder?.products ?? []).map((product: Product) => {
      const errorMessage = getErrorMessage(product, patientDetails?.patient_code, inventory).trim();
      const isDisabled = !!(
        errorMessage ||
        (patientDetails?.patient_code && product.base_quantity === 0) ||
        product.is_expired ||
        product.reasoning_toggle ||
        product.is_generative_scripting
      );
      let quantity = product.quantity || product.quantity_base_order;

      if (product.formulation_id !== 5 && product.remaining_units) {
        quantity = quantity
          ? Math.min(quantity, product.remaining_units)
          : Math.min(product.quantity_base_order, product.remaining_units);
      }

      const clientCode = order?.Patient?.client_id ?? patientOrder?.patient.client_code;
      const availability = getAvailability(product, quantity, clientCode);

      const orderProductOption = createOrderProductOption(product, quantity, availability, undefined, order);
      return { ...orderProductOption, isDisabled, subLabel: errorMessage };
    });
    setAvailableProducts(products);
  }, [
    inventory,
    inventoryIsLoading,
    order,
    order?.Patient,
    order?.products,
    patientOrder?.patient,
    patientOrder?.products,
    refill,
    selectedProducts
  ]);

  return { selectedProducts, skippedProducts, availableProducts };
};
