import { jsxs as p, jsx as o } from "@emotion/react/jsx-runtime";
import e from "@mui/material/Dialog";
import g from "@mui/material/DialogActions";
import a from "@mui/material/DialogContent";
import l from "@mui/material/DialogContentText";
import s from "@mui/material/DialogTitle";
function h({ title: r, children: i, contentProps: t, actions: m, actionProps: n, ...f }) {
  return /* @__PURE__ */ p(e, { ...f, children: [
    typeof r == "string" ? /* @__PURE__ */ o(s, { children: r }) : r,
    /* @__PURE__ */ o(a, { ...t, children: typeof i == "string" ? /* @__PURE__ */ o(l, { children: i }) : i }),
    /* @__PURE__ */ o(g, { ...n, children: m })
  ] });
}
export {
  h as C
};
