import settings from '@/constants/constants';
import { FF_AUTO_LOGOUT_ON_EXPIRED_TOKEN_REQUEST } from '@/constants/featureFlags';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';
import { HttpStatus } from '@montugroup/apis-styleguide';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFeatureFlags from './useFeatureFlags';

const logger = new Logger('useAxiosSetup');

logger.info('init');
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getUser() ? AuthService.getUser()?.token : null}`;
axios.defaults.baseURL = settings.url;

export const useAxiosSetup = () => {
  const navigate = useNavigate();
  const { flags } = useFeatureFlags();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    const cleanUpFunctions: Array<() => void> = [];
    const localStorageUser = AuthService.getUser();
    const shouldLogoutOnExpiredTokenRequest = flags[FF_AUTO_LOGOUT_ON_EXPIRED_TOKEN_REQUEST];

    // NOTE: Logging out on request if the auth token has expired is a short term solution to prevent a logged in UI flash if we are about to log the user out anyway
    if (!token && shouldLogoutOnExpiredTokenRequest && localStorageUser) {
      const tokenExpiry = localStorageUser.tokenExpiry && DateTime.fromSeconds(localStorageUser.tokenExpiry);

      const expiredAuthRequestInterceptor = axios.interceptors.request.use(
        (config) => {
          const now = DateTime.now();

          if (tokenExpiry && now > tokenExpiry) {
            logger.info('logging out due to token expiry');
            AuthService.logout({ expired: true, navigate });
            return Promise.reject(new Error('Token has expired'));
          }

          return config;
        },
        (error) => Promise.reject(error)
      );

      cleanUpFunctions.push(() => axios.interceptors.request.eject(expiredAuthRequestInterceptor));
    }

    const expiredAuthReponseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const status = error?.response?.status;
        if (status === HttpStatus.UNAUTHORIZED && AuthService.isLoggedIn()) {
          logger.info('logging out due to UNAUTHORIZED error');
          AuthService.logout({ expired: true, navigate });
          return;
        }

        delete error.config?.headers?.Authorization;
        delete error.response?.config?.headers?.Authorization;

        return Promise.reject(error);
      }
    );

    cleanUpFunctions.push(() => axios.interceptors.response.eject(expiredAuthReponseInterceptor));

    return () => {
      for (const cleanUpFunction of cleanUpFunctions) {
        cleanUpFunction();
      }
    };
  }, [flags, navigate, token]);
};
