import { useFormContext } from 'react-hook-form';

type RepeatsTableCellProps = {
  index: number;
};
export function RepeatsTableCell({ index }: RepeatsTableCellProps) {
  const { watch } = useFormContext();

  const product = watch(`products.${index}`);

  if (!product.remainingUnits) {
    return null;
  }

  return Math.floor(product.remainingUnits / product.quantity);
}
