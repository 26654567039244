import { jsxs as p, jsx as o } from "@emotion/react/jsx-runtime";
import C from "@mui/icons-material/Delete";
import L from "@mui/icons-material/UploadFile";
import { Typography as c } from "@mui/material";
import i from "@mui/material/Box";
import M from "@mui/material/IconButton";
import { styled as m } from "@mui/material/styles";
import { useState as d, useEffect as v, useCallback as B } from "react";
import P from "@mui/material/LinearProgress";
import w from "@mui/material/Typography";
const D = (e) => /* @__PURE__ */ p(i, { sx: { display: "flex", alignItems: "center" }, children: [
  /* @__PURE__ */ o(i, { sx: { width: "100%", mr: 1 }, children: /* @__PURE__ */ o(P, { variant: "determinate", ...e }) }),
  /* @__PURE__ */ o(i, { sx: { minWidth: 35 }, children: /* @__PURE__ */ o(
    w,
    {
      variant: "body2",
      sx: { color: "text.secondary", fontWeight: "600" },
      children: `${Math.round(e.value)}%`
    }
  ) })
] }), I = (e) => {
  const n = ["Bytes", "KB", "MB", "GB"];
  for (let t = 1; t < n.length; ++t)
    if (e.size < Math.pow(1024, t)) {
      const f = n[t - 1], s = Math.pow(1024, t - 1);
      return `${t === 1 ? e.size : (e.size / s).toFixed(2)} ${f}`;
    }
  return `${e.size}`;
}, T = {
  completed: "Complete",
  failed: "Failed",
  "in-progress": "Loading",
  pending: "Pending"
}, $ = m(i)(({ theme: e }) => ({
  display: "flex",
  flexDirection: "row",
  gap: e.spacing(2),
  alignItems: "center"
})), A = m(i)(() => ({
  display: "flex",
  flexDirection: "column"
})), E = m(i)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column"
})), W = m(i)(({ theme: e }) => ({
  display: "flex",
  flexDirection: "row",
  gap: e.spacing(1),
  color: e.palette.text.secondary
})), X = (e) => {
  const { file: n, action: t } = e, [f, s] = d(0), [x, g] = d(""), [h, l] = d("pending"), [a, u] = d(null), F = I(n);
  v(() => {
    const z = t(n, {
      onProgress: (r) => {
        s(r), l("in-progress");
      },
      onComplete: () => {
        var r;
        s(100), l("completed"), (r = e.onComplete) == null || r.call(e, n);
      },
      onFailed: (r) => {
        l("failed"), g(r);
      }
    });
    u(z);
  }, []);
  const S = B(() => {
    a == null || a.abort(), l("failed"), g("Upload cancelled");
  }, [a, l, g]), y = h === "failed" ? "error" : "info", U = T[h], b = h === "failed" ? x : F;
  return /* @__PURE__ */ p($, { children: [
    /* @__PURE__ */ o(L, { color: y }),
    /* @__PURE__ */ p(E, { children: [
      /* @__PURE__ */ o(c, { children: n.name }),
      /* @__PURE__ */ p(W, { children: [
        /* @__PURE__ */ o(c, { variant: "body2", children: b }),
        /* @__PURE__ */ o(c, { variant: "body2", children: "·" }),
        /* @__PURE__ */ o(c, { variant: "body2", children: U })
      ] }),
      /* @__PURE__ */ o(D, { value: f, color: y })
    ] }),
    /* @__PURE__ */ o(A, { children: /* @__PURE__ */ o(M, { onClick: S, children: /* @__PURE__ */ o(C, {}) }) })
  ] });
};
export {
  X as F
};
