import { jsx as r, jsxs as e } from "@emotion/react/jsx-runtime";
import { ErrorOutlineOutlined as f, ReportProblemOutlined as p, CheckCircleOutlineOutlined as y, InfoOutlined as u } from "@mui/icons-material";
import i from "@mui/material/Box";
import { styled as g } from "@mui/material/styles";
import { toast as a, ToastContainer as d } from "react-toastify";
import T from "@mui/material/Typography";
const t = ({ children: o, ...s }) => /* @__PURE__ */ r(T, { ...s, children: o });
t.displayName = "Typography";
const v = g(i)(({ theme: o }) => ({
  "--toastify-color-info": (o.vars || o).palette.info.main,
  "--toastify-color-success": (o.vars || o).palette.success.main,
  "--toastify-color-warning": (o.vars || o).palette.warning.main,
  "--toastify-color-error": (o.vars || o).palette.error.main,
  "--toastify-color-progress-info": (o.vars || o).palette.info.main,
  "--toastify-color-progress-success": (o.vars || o).palette.success.main,
  "--toastify-color-progress-warning": (o.vars || o).palette.warning.main,
  "--toastify-color-progress-error": (o.vars || o).palette.error.main
})), w = ({ title: o, message: s }) => /* @__PURE__ */ e(i, { children: [
  o && /* @__PURE__ */ r(t, { component: "h2", variant: "body1", children: o }),
  /* @__PURE__ */ r(t, { variant: "body2", children: s })
] }), m = {
  info: /* @__PURE__ */ r(u, { color: "info" }),
  success: /* @__PURE__ */ r(y, { color: "success" }),
  warning: /* @__PURE__ */ r(p, { color: "warning" }),
  error: /* @__PURE__ */ r(f, { color: "error" }),
  default: null
}, n = (o) => (s, c) => o(/* @__PURE__ */ r(w, { ...typeof s == "string" ? { message: s } : s }), {
  // apply the mui icons instead of the defaults
  icon: ({ type: l }) => m[l] || null,
  ...c
}), B = (o) => /* @__PURE__ */ r(v, { children: /* @__PURE__ */ r(d, { ...o }) }), I = Object.assign((o) => n(a)(o), a, {
  info: n(a.info),
  success: n(a.success),
  error: n(a.error),
  warning: n(a.warning),
  warn: n(a.warn)
});
export {
  B as T,
  t as a,
  I as t
};
