import { HttpStatus } from '@montugroup/apis-styleguide';
import { apiPatientPortalContract } from '@montugroup/pms-api-contracts';
import { useMutation } from '@tanstack/react-query';
import { TsRestResponseError } from '@ts-rest/core';
import axios from 'axios';

import { getTsPatientPortalClient } from '@/api/patient-portal.client';
import settings from '@/constants/constants';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGenerateDiscountCode');

type GenerateDiscountCodePayload = {
  promoCode: string;
  message: string;
};

/**
 * Hook that requests generation of a discount code
 *
 * @returns Fetcher function, status variables, returned data
 */
export function useGenerateDiscountCode() {
  const { flags } = useFeatureFlags();
  const ffEnableShopifyDiscountGeneration = flags.enable_shopify_discount_generation;
  const tsrClient = getTsPatientPortalClient();

  const generateDiscountCodeMutation = useMutation({
    mutationKey: ['generate-discount-code'],
    mutationFn: async (patientCode?: string) => {
      // check for user token
      const token = AuthService.getUser()?.token;
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (ffEnableShopifyDiscountGeneration) {
        const response = await tsrClient.getWomDiscountCode.mutate({
          body: patientCode ? { patientCode } : undefined
        });

        if (response.status !== HttpStatus.OK && response.status !== HttpStatus.CREATED) {
          throw new TsRestResponseError(apiPatientPortalContract.getWomDiscountCode, response);
        }

        return response.body.data;
      }

      // use legacy endpoint if feature flag is disabled
      const url = `${settings.url}/patient/consultations/referrals`;
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return response.data as GenerateDiscountCodePayload;
    },
    retry: false,
    onSuccess: (data) => {
      logger.info('Discount code generated successfully:', data);
    },
    onError: (error) => {
      logger.error(`Error generating discount code:`, {
        error,
        client: ffEnableShopifyDiscountGeneration ? 'ts-rest' : 'axios'
      });
    }
  });

  const generateDiscountCode = async (patientCode?: string) => {
    try {
      return await generateDiscountCodeMutation.mutateAsync(patientCode);
    } catch (error) {
      logger.error('Error in generateDiscountCode:', error);
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Failed to generate discount code');
    }
  };

  return {
    generateDiscountCode,
    isPending: generateDiscountCodeMutation.isPending,
    isError: generateDiscountCodeMutation.isError,
    isSuccess: generateDiscountCodeMutation.isSuccess,
    data: generateDiscountCodeMutation.data
  };
}

export default useGenerateDiscountCode;
