import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useState } from 'react';

import { FF_ENABLE_TASMANIAN_DOCTOR_FOLLOW_UP } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useUserProfileData from '@/hooks/user/useGetUserProfile';
import type { BookConsultationButtonProps } from './BookConsultationButton';
import BookConsultationButton from './BookConsultationButton';

export type BookRegularOrNewDoctorButtonProps = BookConsultationButtonProps & {
  currentDoctorLink: string;
};

const DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL = 'team/alternaleaf-doctors/follow-up-doctor-consultation';
const TASMANIAN_DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL = 'team/alternaleaf-doctors/follow-up-doctor-consultation-tasmania';

const TAS_STATE_ID = 275;

/**
 * For use when a patient makes the choice to see different Doctor to their currently assigned
 */
export default function BookRegularOrNewDoctorButton({
  buttonProps,
  disabled,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
  togglePrimaryColours = false,
  currentDoctorLink
}: BookRegularOrNewDoctorButtonProps) {
  const { flags } = useFeatureFlags();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [modalOneOpen, setModalOneOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const { data, isLoading } = useUserProfileData();
  const ffEnableTasmanianFollowup = flags[FF_ENABLE_TASMANIAN_DOCTOR_FOLLOW_UP];

  const useTasmanianFollowup = ffEnableTasmanianFollowup && data?.user?.state_id === TAS_STATE_ID;
  const followupRoundRobinUrl = useTasmanianFollowup
    ? TASMANIAN_DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL
    : DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL;

  return (
    <>
      <Button data-testid="regular-or-new-doctor-prompt-button" {...buttonProps} onClick={() => setModalOneOpen(true)}>
        Book consultation
      </Button>
      <Dialog fullWidth maxWidth="sm" open={modalOneOpen} onClose={() => setModalOneOpen(false)} disableScrollLock>
        <Box className="p-2" sx={{ position: 'relative' }}>
          <IconButton
            aria-label="close"
            data-testid="regular-or-new-doctor-dialog-button-cancel"
            onClick={() => setModalOneOpen(false)}
            size="small"
            sx={{
              position: 'absolute',
              top: 16,
              right: 16
            }}
          >
            <GridCloseIcon />
          </IconButton>

          <DialogTitle
            data-testid="regular-or-new-doctor-dialog-title"
            component="h5"
            variant="h5"
            sx={{
              color: theme.palette.primary.main
            }}
          >
            Book a consultation
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4}>
              <Stack
                direction="row"
                className="p-3"
                sx={{
                  backgroundColor: '#FFF4E5'
                }}
              >
                {isSmUp && <WarningAmberIcon sx={{ color: theme.palette.secondary.main }} />}
                <Box>
                  <Typography
                    data-testid="doctor-availability-info"
                    className={isSmUp ? 'pl-2 pb-2' : ''}
                    fontWeight={theme.typography.fontWeightBold}
                  >
                    Availability with your current doctor is limited or fully booked.
                  </Typography>
                  <Typography className={isSmUp ? 'pl-2' : ''}>
                    If you’d prefer not to wait, you can book an earlier appointment with another doctor using the
                    button below. If you&apos;re happy to wait, feel free to browse your current doctor’s upcoming
                    availability to find a suitable appointment.
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={isSmUp ? 'row' : 'column'} spacing={3} width={{ xs: '100%', sm: 'inherit' }} paddingX={4}>
              <BookConsultationButton
                calcomUrl={currentDoctorLink}
                testId={'regular-or-new-doctor-dialog-button-regular'}
                buttonProps={{
                  variant: 'outlined',
                  color: togglePrimaryColours ? 'primary' : 'secondary',
                  sx: { marginRight: '0.5rem' },
                  fullWidth: !isSmUp
                }}
                disabled={disabled}
                handleBookingSuccess={handleBookingSuccess}
                handleRescheduleSuccess={handleRescheduleSuccess}
                handleCancelSuccess={handleCancelSuccess}
                onOpen={() => setModalOneOpen(false)}
              >
                Book with My Doctor
              </BookConsultationButton>
              <Button
                data-testid="regular-or-new-doctor-dialog-button-new"
                color={togglePrimaryColours ? 'primary' : 'secondary'}
                variant="contained"
                onClick={() => {
                  setModalOneOpen(false);
                  setConfirmationModalOpen(true);
                }}
                disabled={disabled}
                sx={{ width: { xs: '100%', sm: 'inherit' } }}
              >
                Book with next available doctor
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        data-testid="confirm-new-doctor-dialog"
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        disableScrollLock
      >
        <Box className="p-2 pt-4">
          <Stack direction="row" alignItems="center" className="pl-3">
            {isSmUp && <WarningAmberIcon sx={{ color: theme.palette.secondary.main }} />}
            <Typography
              data-testid="confirm-new-doctor-dialog-title"
              variant="h5"
              className={isSmUp ? 'pl-2' : ''}
              sx={{
                color: theme.palette.primary.main
              }}
            >
              Consultation with a new Doctor
            </Typography>
          </Stack>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4)
            }}
          >
            <Typography fontWeight={theme.typography.fontWeightBold}>
              Booking an appointment with a new doctor
            </Typography>
            <Typography>
              By confirming this booking, you acknowledge that you will be assigned a new doctor. If a new prescription
              is issued, any existing prescriptions will be removed from the system and will no longer be available for
              dispensing.
            </Typography>
            <Typography>
              If you wish to continue with any previously prescribed products, please discuss this with your doctor
              during your consultation.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="confirm-new-doctor-dialog-button-cancel"
              color={togglePrimaryColours ? 'primary' : 'secondary'}
              variant="text"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            <BookConsultationButton
              calcomUrl={followupRoundRobinUrl}
              testId={
                useTasmanianFollowup
                  ? 'confirm-new-tasmanian-doctor-dialog-button-confirm'
                  : 'confirm-new-doctor-dialog-button-confirm'
              }
              onOpen={() => setConfirmationModalOpen(false)}
              disabled={isLoading || disabled}
              togglePrimaryColours={togglePrimaryColours}
            >
              Confirm my booking
            </BookConsultationButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
