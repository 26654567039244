import { AdminPageSection } from '@/components/layout/AdminPageSection';
import { AddProductButton } from '@/components/order/OrderProducts/AddProductButton';
import { PrescriptionTableCell } from '@/components/order/OrderProducts/TableCells/PrescriptionTableCell';
import { ProductTableCell } from '@/components/order/OrderProducts/TableCells/ProductTableCell';
import { QuantityTableCell } from '@/components/order/OrderProducts/TableCells/QuantityTableCell';
import { RepeatsTableCell } from '@/components/order/OrderProducts/TableCells/RepeatsTableCell';
import settings from '@/constants/constants';
import type { OrderFormValues } from '@/context/order/OrderFormMeta';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { OrderProductOption } from '@/hooks/order/useOrderFormProducts';
import { Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ActionsTableCell } from './TableCells/ActionsTableCell';

export function OrderProductsTable() {
  const { isLoading, order, patientOrder, isEditing, isExistingOrder, isNewOrder, isRefill } = useOrderFormMeta();

  const { watch, trigger } = useFormContext();
  const { fields, append, update, remove } = useFieldArray<OrderFormValues>({
    name: 'products',
    rules: {
      minLength: 1
    }
  });
  const products = watch('products');
  const isReadOnly = !isEditing && !isNewOrder;

  const handleProductChanged = useCallback(
    async (value: OrderProductOption, index: number) => {
      await update(index, { ...value, quantity: Math.min(value.quantity ?? 1, value.baseQuantity ?? 1) });
      await trigger();
    },
    [update, trigger]
  );

  const handleAddProduct = () => {
    append({
      productId: null,
      quantity: null,
      repeats: null,
      dispensedDate: null,
      intervalEndDate: null,
      doctorName: null,
      prescriptionCode: null,
      supplierName: '',
      formulationId: null
    });
  };

  const handleRemoveProduct = (index: number) => {
    remove(index);
  };

  const hasClientId = Boolean(order?.Patient?.client_id ?? patientOrder?.patient.client_code);

  const LoadingState = () => (
    <TableRow>
      {Array.from({ length: 8 }, (_x, i) => i).map((elm) => (
        <TableCell key={elm}>
          <Skeleton height={73} />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <AdminPageSection title="Product Details">
      <Stack gap={2}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              {hasClientId && (
                <>
                  <TableCell>Repeats left</TableCell>
                  <TableCell>Doctor</TableCell>
                  <TableCell>Interval</TableCell>
                  <TableCell>Interval End Date</TableCell>
                  <TableCell>Prescription</TableCell>
                </>
              )}
              {isExistingOrder || (isRefill && <TableCell>Pharmacy</TableCell>)}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <LoadingState />}
            {!isLoading &&
              fields.map((row, index) => {
                const orderProductOption = products[index];
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <ProductTableCell
                        index={index}
                        selectedProducts={products}
                        productOption={orderProductOption}
                        isReadOnly={isReadOnly}
                        onChange={handleProductChanged}
                      />
                    </TableCell>
                    <TableCell>
                      <QuantityTableCell index={index} isReadOnly={isReadOnly} />
                    </TableCell>
                    {hasClientId && (
                      <>
                        <TableCell>
                          <RepeatsTableCell index={index} />
                        </TableCell>
                        <TableCell>{orderProductOption?.doctorName}</TableCell>
                        <TableCell>{orderProductOption?.interval}</TableCell>
                        <TableCell>
                          {order?.order_status_id === settings.orderStatus.INTERVAL_TIME &&
                          orderProductOption?.dispensedDate
                            ? orderProductOption.intervalEndDate
                            : '-'}
                        </TableCell>
                        <TableCell>
                          <PrescriptionTableCell orderProductOption={orderProductOption} />
                        </TableCell>
                      </>
                    )}
                    {isExistingOrder && <TableCell>{orderProductOption.lastDispensedLocation}</TableCell>}
                    <TableCell>
                      {!isReadOnly ? <ActionsTableCell handleClick={() => handleRemoveProduct(index)} /> : null}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <AddProductButton handleClick={handleAddProduct} />
      </Stack>
    </AdminPageSection>
  );
}
