import type { AxiosError } from 'axios';

// TODO: This can be extracted to somewhere more common when needed elsewhere
export type RequestError = Error | AxiosError;

export type OnAcceptanceFields = { marketingOptIn?: boolean, agreementType: UserAgreement['agreementType'] };

export enum AgreementType {
  PrivacyPolicy = 'privacy_policy',
  TermsAndConditions = 'terms_and_conditions'
}

export interface UserAgreement {
    id: number;
    userId: number;
    agreementType: AgreementType;
    version: number | null;
  }
  
  export interface AgreementState {
    privacyPolicy: boolean;
    termsAndConditions: boolean;
  }
  