import type { OrderProductOption } from '@/hooks/order/useOrderFormProducts';
import { useOpenPrescription } from '@/hooks/prescription/useOpenPrescription';
import { Button, Link } from '@mui/material';

type PrescriptionTableCellProps = {
  orderProductOption: OrderProductOption;
};
export function PrescriptionTableCell({ orderProductOption }: PrescriptionTableCellProps) {
  const { prescriptionCode } = orderProductOption;
  const { openPrescription } = useOpenPrescription();

  if (!prescriptionCode || prescriptionCode === '-') {
    return '-';
  }
  return (
    <Link component={Button} variant="body2" onClick={() => openPrescription(prescriptionCode)}>
      {prescriptionCode}
    </Link>
  );
}
