import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

export default function useGetProductsFromInventory(pharmacyId: number | string | undefined) {
  return useQuery({
    queryKey: ['productsFromInventory', pharmacyId],
    queryFn: async () => {
      if (pharmacyId !== undefined && pharmacyId !== '-') {
        const response = await OrderService.getProductsFromInventory(pharmacyId as number);
        if (response.status === 200) {
          return response.data;
        }
      }
      return [];
    }
  });
}
