import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import { Box, Skeleton, Stack, Typography } from '@mui/material';

export const NonReplacementOrderDiscount = () => {
  const { order, isLoading } = useOrderFormMeta();

  if (isLoading) {
    return (
      <Box data-testid="loading">
        <Skeleton width="100%" height={20} variant="rectangular" />
      </Box>
    );
  }

  return (
    <Box>
      <Stack>
        <Typography variant="body1" fontWeight="bold">
          {order?.order_code}
        </Typography>
        {order?.OrderDiscounts &&
          order.OrderDiscounts.map(
            (discount) =>
              discount.DiscountType.name !== 'Full Order' && (
                <Typography key={discount.id}>
                  {`${discount.discount_percentage}%`} - {discount.DiscountType.name}
                  {discount.DiscountReason ? ` - ${discount.DiscountReason.reason}` : ''}
                  {discount.discount_note ? ` - ${discount.discount_note}` : ''}
                </Typography>
              )
          )}
      </Stack>
    </Box>
  );
};

export default NonReplacementOrderDiscount;
