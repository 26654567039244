import { anonymousUserContext, convertToLDIdentityContext } from '@/utils/convertToLDIdentityContext';
import { Logger } from '@/utils/logger';
import type { LDContext } from 'launchdarkly-react-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useCallback, useEffect, useRef, useState, useSyncExternalStore } from 'react';
import useUser from './user/useUser';

const logger = new Logger('useUserFeatureFlagsSync');

/**
 * Subscribes to LaunchDarkly's identity context
 *
 * @returns LDContext | null
 */
export function useLDIdentityContext() {
  const ldClient = useLDClient();

  const previousContextRef = useRef<LDContext | null>(null);

  const getSnapshot = () => {
    if (!ldClient) {
      return null;
    }

    const currentContext = ldClient.getContext();

    // Only return a new reference if the context has actually changed
    if (JSON.stringify(previousContextRef.current) !== JSON.stringify(currentContext)) {
      previousContextRef.current = currentContext;
    }

    return previousContextRef.current;
  };

  const subscribe = useCallback(
    (callback: () => void) => {
      if (!ldClient) {
        return () => {};
      }
      const listener = () => callback();
      ldClient.on('change:context', listener);
      return () => ldClient.off('change:context', listener);
    },
    [ldClient]
  );

  return useSyncExternalStore(subscribe, getSnapshot);
}

/**
 * Should only be used from App.tsx. Checks for user change and updates the feature flag client to ensure we are showing the correct flags for the given user
 *
 * @returns loading
 */
export const useUserFeatureFlagsSync = () => {
  const ldClient = useLDClient();
  const ldIdentityContext = useLDIdentityContext();
  const { data: user } = useUser();

  const needsLDIdentificationSet = !!user?.id && `${user.id}` !== ldIdentityContext?.key;
  const needsLDIdentificationCleared = !user?.id && ldIdentityContext?.key !== anonymousUserContext.key;
  const needsLDIdentificationChanged = needsLDIdentificationSet || needsLDIdentificationCleared;
  const [loading, setLoading] = useState(needsLDIdentificationChanged);

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    if (needsLDIdentificationChanged) {
      setLoading(true);
      (async () => {
        const newLDContext = convertToLDIdentityContext(user);

        await ldClient.waitUntilReady();
        await ldClient.identify(newLDContext);
        logger.info('LD client changed identity success', { from: ldIdentityContext?.key, to: newLDContext.key });
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [ldClient, user, needsLDIdentificationChanged, ldIdentityContext]);

  return {
    loading: loading || needsLDIdentificationChanged
  };
};
