import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

import { PharmacyService } from '@/services/pharmacy.service';

import type { Coordinates } from './pharmacyAllocationStore';
import usePharmacyStore from './pharmacyAllocationStore';

function isValidLatLon(latLon: string): boolean {
  return /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10}),-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$$/.test(latLon.trim());
}

function stringifyLatLon(coordinates: Coordinates | null): string {
  if (!coordinates) {
    return '';
  }

  return `${coordinates.latitude},${coordinates.longitude}`;
}

function parseLatLon(rawLatLon: string): Coordinates | null {
  if (!isValidLatLon(rawLatLon)) {
    return null;
  }
  const [latitude, longitude] = rawLatLon.split(',');

  return {
    latitude,
    longitude
  };
}

function PharmacyAllocationSettings() {
  const { originalData, modifications, setFetchedData, modifyPharmacy, resetModifications } = usePharmacyStore();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PharmacyService.getPharmacyAllocationSettings();
        setFetchedData(response.data.data);
      } catch {
        setError('Failed to fetch pharmacy allocation settings');
      }
    };
    fetchData();
  }, [setFetchedData]);

  const handleSave = async () => {
    const modifiedEntries = Object.entries(modifications).map(([id, data]) => ({
      pharmacyId: Number(id),
      ...data,
      coordinates: data.coordinates ? parseLatLon(data.coordinates) : undefined
    }));

    try {
      await PharmacyService.updatePharmacyAllocationSettings(modifiedEntries);
      setError(null);
      const response = await PharmacyService.getPharmacyAllocationSettings();
      setFetchedData(response.data.data);
      resetModifications();
    } catch {
      setError('Failed to update pharmacy allocation settings');
    }
  };

  const isSaveDisabled =
    // There are no modifications
    Object.keys(modifications).length === 0;

  function getLatLonBackgroundColour(pharmacyId: number) {
    const original = originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId);

    if (!original) {
      throw Error(`Pharmacy not found in formatLatLon. pharmacyId: ${pharmacyId}`);
    }

    const modified = modifications[pharmacyId]?.coordinates;

    if (!modified || modified === stringifyLatLon(original.coordinates)) {
      return 'none';
    }

    if (isValidLatLon(modified)) {
      return 'lightgreen';
    }

    return 'salmon';
  }

  function getLatLonInputValue(pharmacyId: number): string {
    const modification = modifications[pharmacyId]?.coordinates;

    if (modification) {
      return modification;
    }

    const original = originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId);

    if (!original) {
      throw Error(`Pharmacy not found in formatLatLon. pharmacyId: ${pharmacyId}`);
    }

    return stringifyLatLon(original.coordinates);
  }

  if (!originalData) {
    return <CircularProgress color="info" size={25} />;
  }

  return (
    <Box>
      <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3" gutterBottom>
        Pharmacy Allocation Settings
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button sx={{ align: 2 }} onClick={handleSave} disabled={isSaveDisabled}>
          Save
        </Button>
      </Box>
      <Table sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Pharmacy Code</TableCell>
            <TableCell>Pharmacy Name</TableCell>
            <TableCell>Dispensed Today</TableCell>
            <TableCell>Currently Allocated</TableCell>
            <TableCell>Total Orders Today</TableCell>
            <TableCell>Daily Dispense Limit</TableCell>
            <TableCell>Flexible Dispense Limit</TableCell>
            <TableCell>Minimum Delivery Distance (KM)</TableCell>
            <TableCell>Lat/Lon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
          {originalData.map((pharmacy) => (
            <TableRow key={pharmacy.pharmacyId}>
              <TableCell>{pharmacy.pharmacyCode}</TableCell>
              <TableCell>{pharmacy.pharmacyName.replace('(HOME DELIVERY)', '').trim()}</TableCell>
              <TableCell>{pharmacy.stats.counts.dispensedToday}</TableCell>
              <TableCell>{pharmacy.stats.counts.pendingDispense}</TableCell>
              <TableCell>{pharmacy.stats.counts.totalToday}</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={modifications[pharmacy.pharmacyId]?.dailyDispenseLimit ?? pharmacy.stats.limits.daily.limit}
                  onChange={(e) => modifyPharmacy(pharmacy.pharmacyId, { dailyDispenseLimit: Number(e.target.value) })}
                  style={{
                    background: modifications[pharmacy.pharmacyId]?.dailyDispenseLimit ? 'lightgreen' : 'none'
                  }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  checked={modifications[pharmacy.pharmacyId]?.flexibleDispenseLimit ?? pharmacy.flexibleDispenseLimit}
                  onChange={(e) => modifyPharmacy(pharmacy.pharmacyId, { flexibleDispenseLimit: e.target.checked })}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={
                    modifications[pharmacy.pharmacyId]?.minimumDeliveryDistanceInKm ??
                    pharmacy.minimumDeliveryDistanceInKm
                  }
                  onChange={(e) =>
                    modifyPharmacy(pharmacy.pharmacyId, { minimumDeliveryDistanceInKm: Number(e.target.value) })
                  }
                  style={{
                    background: modifications[pharmacy.pharmacyId]?.minimumDeliveryDistanceInKm ? 'lightgreen' : 'none'
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={getLatLonInputValue(pharmacy.pharmacyId)}
                  onChange={(e) => {
                    const input = e.target.value;
                    modifyPharmacy(pharmacy.pharmacyId, {
                      coordinates: input
                    });
                  }}
                  style={{
                    background: getLatLonBackgroundColour(pharmacy.pharmacyId)
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {error && (
        <Modal open={Boolean(error)} onClose={() => setError(null)}>
          <Alert severity="error">{error}</Alert>
        </Modal>
      )}
    </Box>
  );
}

export default PharmacyAllocationSettings;
