import { Button, Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CircleLoader from '@/components/common/circleLoader';
import type { ErrorProps } from '@/components/error/Error';
import Error from '@/components/error/Error';
import UnauthenticatedLayout from '@/components/layout/UnauthenticatedLayout';
import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { PaymentService } from '@/services/payment.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('PayByInvoice');

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PayByInvoice() {
  const params = useParams<{ token: string }>();
  const [error, setError] = useState<boolean | ErrorProps>(false);
  const navigate = useNavigate();

  const loggedInPatient = AuthService.isPatient();

  const handleRefillClick = () => {
    if (!loggedInPatient) {
      navigate('/login/patient', { state: { from: { pathname: '/patient/refill' } } });
    } else {
      navigate('/patient/refill');
    }
  };

  const getCheckoutRedirectUrlForUrl = async () => {
    setError(false);
    const { token } = params;
    if (!token) {
      // eslint-disable-next-line no-console
      console.error('no token for getCheckoutRedirectUrlForUrl');
      return;
    }
    const {
      checkoutRedirectUrl,
      orderCode,
      error: responseError,
      isPaid,
      paidOrderAttributes,
      userStatus,
      isUnavailable
    } = await PaymentService.getCheckoutRedirectUrlForInvoice(token);

    if (isPaid) {
      navigate('/payment-success', {
        state: {
          orderCode,
          firstName: paidOrderAttributes?.userFirstName,
          email: paidOrderAttributes?.userEmail,
          onBoardKey: paidOrderAttributes?.onBoardKey,
          transactionType: paidOrderAttributes?.transactionType
        }
      });
    } else if (checkoutRedirectUrl) {
      window.location.replace(checkoutRedirectUrl);
    } else if (isUnavailable) {
      setError({
        message: 'This order is no longer valid, please visit the Refill page to place a new order',
        buttons: [
          // eslint-disable-next-line react/jsx-key
          <Button variant="contained" onClick={handleRefillClick}>
            Refill medication
          </Button>
        ]
      });
    } else if (userStatus?.isDischarged) {
      setError({
        message: 'You have been discharged. Please contact our support team.',
        buttons: [
          // eslint-disable-next-line react/jsx-key
          <Button variant="contained" href={`${settings.support.site.url}/requests/new`}>
            Contact support
          </Button>
        ]
      });
    } else if (userStatus?.isUserActive === false) {
      setError({
        message: 'Your account is not active. Please contact our support team.',
        buttons: [
          // eslint-disable-next-line react/jsx-key
          <Button variant="contained" href={`${settings.support.site.url}/requests/new`}>
            Contact support
          </Button>
        ]
      });
    } else {
      logger.error('Error retrieving checkout redirect URL', responseError);
      setError(true);
    }
  };

  useEffect(() => {
    getCheckoutRedirectUrlForUrl();
  }, []);

  return (
    <UnauthenticatedLayout>
      {!error && <CircleLoader />}
      {error && <ContentContainer>{typeof error === 'boolean' ? <Error /> : <Error {...error} />}</ContentContainer>}
    </UnauthenticatedLayout>
  );
}

export default PayByInvoice;
