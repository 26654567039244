import { create } from 'zustand';

export type Coordinates = {
  longitude: string;
  latitude: string;
};

type PharmacyAllocationResponse = {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  minimumDeliveryDistanceInKm: number;
  coordinates: Coordinates | null;
  flexibleDispenseLimit: boolean;
  stats: {
    limits: {
      daily: {
        limit: number;
        used: number;
        available: number;
      };
    };
    counts: {
      pendingDispense: number;
      dispensedToday: number;
      totalToday: number;
    };
  };
}[];

type Modifications = Record<
  number,
  {
    dailyDispenseLimit?: number;
    flexibleDispenseLimit?: boolean;
    coordinates?: string;
    minimumDeliveryDistanceInKm?: number;
  }
>;

interface PharmacyState {
  originalData: PharmacyAllocationResponse | null;
  modifications: Modifications;
  setFetchedData: (data: PharmacyAllocationResponse) => void;
  modifyPharmacy: (pharmacyId: number, data: Partial<Modifications[number]>) => void;
  resetModifications: () => void;
}

const usePharmacyStore = create<PharmacyState>((set) => ({
  originalData: null,
  modifications: {},
  setFetchedData: (data) => {
    set({
      originalData: data.sort((pharmA, pharmB) =>
        pharmA.pharmacyName.localeCompare(pharmB.pharmacyName, 'en', { sensitivity: 'base' })
      ),
      modifications: {}
    });
  },
  modifyPharmacy: (pharmacyId, data) =>
    set((state) => ({
      modifications: {
        ...state.modifications,
        [pharmacyId]: {
          ...state.modifications[pharmacyId],
          ...data
        }
      }
    })),
  resetModifications: () => set({ modifications: {} })
}));

export default usePharmacyStore;
