import { apiV1Contract } from '@montugroup/circuit-api-contracts';

import { createTsRestClient } from './ts-rest.client';

let cachedClient: ReturnType<typeof createTsRestClient<typeof apiV1Contract>> | null = null;

export const getTsApiV1Client = () => {
  if (!cachedClient) {
    cachedClient = createTsRestClient(apiV1Contract);
  }

  return cachedClient;
};

export default null;
