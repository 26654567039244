import settings from '@/constants/constants';
import type { OrderProductOption, OrderProductOptionAvailability } from '@/hooks/order/useOrderFormProducts';
import type { Order, OrderProduct, PatientActivePrescription, Product, ProductInventoryStatus } from '@/types';
import { DateTime } from 'luxon';

export type OrderPrescriptionDetails = {
  patientPrescriptions: PatientActivePrescription[];
  prescription: PatientActivePrescription | null;
  doctorName: string | null;
  prescriptionCode: string | null;
  interval: number | null;
};

export const EMPTY_PRODUCT_OPTION: OrderProductOption = {
  productId: null,
  quantity: null,
  repeats: null,
  dispensedDate: null,
  intervalEndDate: null,
  doctorName: null,
  prescriptionCode: null,
  supplierName: '',
  formulationId: null
};

export const getErrorMessage = (
  product: Product,
  pms_client: string | undefined,
  inventory: ProductInventoryStatus[] = []
) => {
  if (product.is_generative_scripting) {
    return 'Discontinued';
  }
  if (pms_client) {
    if (product.is_expired) {
      return 'Prescription expired - need new prescription';
    }
    if (Number(product.remaining_units) <= 0 && product.formulation_id !== 5) {
      return 'Out of repeats - need new prescription';
    }
  }
  if (product.isProductLocked) {
    return product.intervalEndDate
      ? `Interval Ends: ${DateTime.fromFormat(product.intervalEndDate, 'MMMM dd, yyyy').toLocal().toFormat('dd MMM yyyy')}`
      : 'Product locked - interval end date unknown';
  }

  const inventoryProduct = inventory.find((item) => Number(item.productId) === Number(product.id));
  if (!inventoryProduct) {
    if (product.is_out_of_stock) {
      return product.stock_return_date
        ? `Out of stock - Expected return ${DateTime.fromISO(product.stock_return_date).toLocal().toFormat('dd MMM yyyy')}`
        : 'Out of stock - Expected return date unknown';
    }
    if (product.reasoning_toggle) {
      return `Out of Stock - ${product.reasoning}`;
    }
  }
  return '';
};

export const getIntervalEndDate = (lastDispensedDate: string, interval: number | null | undefined) => {
  if (!lastDispensedDate || !interval) {
    return '-';
  }
  const intervalEndDate = DateTime.fromFormat(lastDispensedDate, 'dd-MMM-yyyy').plus({ days: interval });
  return DateTime.now() > intervalEndDate ? '-' : intervalEndDate.toFormat('dd-MMM-yyyy');
};

export const formatDate = (date: string | null | undefined) => {
  return date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat('dd-MMM-yyyy') : null;
};

export const getSupplierName = (product: Product) => product.Suppliers?.[0]?.supplier_name ?? 'Others';

export const getOrderPrescriptionDetails = (
  prescriptions: PatientActivePrescription[] | undefined,
  product_id: number,
  quantity: number
): OrderPrescriptionDetails | null => {
  if (!prescriptions) {
    return null;
  }
  const matchingPrescriptions = prescriptions.filter((pr) => pr.product_id === product_id);
  const validPrescriptions = matchingPrescriptions.filter(
    (pr) => pr.quantity >= quantity && pr.remaining_units >= quantity
  );

  // If no valid prescriptions, fall back to the ones with quantity <= requested
  const patientPrescriptions = validPrescriptions.length
    ? validPrescriptions
    : matchingPrescriptions.filter((pr) => pr.quantity <= quantity);

  if (!patientPrescriptions.length) {
    return { patientPrescriptions: [], prescription: null, doctorName: null, prescriptionCode: null, interval: null };
  }

  // Find prescription with the smallest interval
  const prescription = patientPrescriptions.reduce(
    (min, pr) => (pr.interval < min.interval ? pr : min),
    patientPrescriptions[0]
  );

  return {
    patientPrescriptions,
    prescription,
    doctorName: prescription?.Prescription?.GeneralPractitioner?.Doctor
      ? `${prescription.Prescription.GeneralPractitioner.Doctor.first_name} ${prescription.Prescription.GeneralPractitioner.Doctor.last_name}`
      : null,
    prescriptionCode: prescription?.Prescription?.prescription_code || null,
    interval: prescription?.interval ?? null
  };
};

export const createOrderProductOption = (
  product: Product,
  quantity: number,
  availability: OrderProductOptionAvailability,
  orderProduct?: OrderProduct,
  order?: Order
): OrderProductOption => {
  const orderPrescriptionDetails = getOrderPrescriptionDetails(order?.patientActivePrescriptions, product.id, quantity);

  const getDoctorName = () => {
    if (orderPrescriptionDetails && orderPrescriptionDetails.doctorName) {
      return orderPrescriptionDetails.doctorName;
    }
    if (order?.Patient.client_id && orderProduct?.PrescriptionProduct) {
      return `${orderProduct?.PrescriptionProduct?.Prescription?.GeneralPractitioner?.Doctor?.first_name} ${orderProduct?.PrescriptionProduct?.Prescription?.GeneralPractitioner?.Doctor?.last_name}`;
    }
    return '-';
  };

  const getPrescriptionCode = () => {
    if (orderPrescriptionDetails && orderPrescriptionDetails.prescriptionCode) {
      return orderPrescriptionDetails.prescriptionCode;
    }
    if (order?.Patient.client_id && orderProduct?.PrescriptionProduct) {
      return orderProduct?.PrescriptionProduct?.Prescription?.prescription_code;
    }
    return '-';
  };

  const getLastDispensedLocation = () => {
    if (
      order &&
      [settings.orderStatus.SHIPPED, settings.orderStatus.PHARMACY_DISPENSED].includes(order.order_status_id)
    ) {
      return order.RefillPharmacy?.name ?? '';
    }
    return '';
  };

  return {
    productId: product.id,
    productName: product.name,
    remainingUnits: product.remaining_units ?? 0,
    supplierName: getSupplierName(product),
    formulationId: product.formulation_id,
    lastDispensedLocation: getLastDispensedLocation(),
    quantity,
    ...availability,
    ...(order?.Patient.client_id && { interval: orderProduct?.PrescriptionProduct?.interval }),
    shippedDate: formatDate(order?.shipped_date) ?? '',
    doctorName: getDoctorName(),
    ...(orderPrescriptionDetails?.interval ? { interval: orderPrescriptionDetails?.interval } : {}),
    prescriptionCode: getPrescriptionCode()
  };
};

export const getAvailability = (
  product: Product,
  quantity: number,
  clientCode: string | undefined | number | null,
  dispensedDate?: string
) => {
  const baseQuantity =
    clientCode && product.formulation_id !== 5
      ? Math.max(isNaN(product.quantity_base_order) ? 0 : product.quantity_base_order, quantity)
      : 12;

  return {
    repeats: product.remaining_units ? Math.floor(product.remaining_units / quantity) : '',
    intervalEndDate: dispensedDate ? getIntervalEndDate(dispensedDate, product.interval) : '-',
    baseQuantity: baseQuantity,
    dispensedDate: formatDate(dispensedDate) ?? '-'
  } as OrderProductOptionAvailability;
};
