import { jsx as he } from "@emotion/react/jsx-runtime";
import H, { useRef as ee, useState as be, useEffect as ce, useLayoutEffect as Pe, useMemo as Me } from "react";
import { T as Se } from "../utils/TextField.js";
function de(e, r) {
  var t = {};
  for (var n in e)
    Object.prototype.hasOwnProperty.call(e, n) && r.indexOf(n) < 0 && (t[n] = e[n]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, n = Object.getOwnPropertySymbols(e); a < n.length; a++)
      r.indexOf(n[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, n[a]) && (t[n[a]] = e[n[a]]);
  return t;
}
var ae;
(function(e) {
  e.event = "event", e.props = "prop";
})(ae || (ae = {}));
function $() {
}
function Le(e) {
  var r, t = void 0;
  return function() {
    for (var n = [], a = arguments.length; a--; ) n[a] = arguments[a];
    return r && n.length === r.length && n.every(function(i, u) {
      return i === r[u];
    }) || (r = n, t = e.apply(void 0, n)), t;
  };
}
function Q(e) {
  return !!(e || "").match(/\d/);
}
function J(e) {
  return e == null;
}
function Ke(e) {
  return typeof e == "number" && isNaN(e);
}
function Ve(e) {
  return J(e) || Ke(e) || typeof e == "number" && !isFinite(e);
}
function Ne(e) {
  return e.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
}
function $e(e) {
  switch (e) {
    case "lakh":
      return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
    case "wan":
      return /(\d)(?=(\d{4})+(?!\d))/g;
    case "thousand":
    default:
      return /(\d)(?=(\d{3})+(?!\d))/g;
  }
}
function Ue(e, r, t) {
  var n = $e(t), a = e.search(/[1-9]/);
  return a = a === -1 ? e.length : a, e.substring(0, a) + e.substring(a, e.length).replace(n, "$1" + r);
}
function We(e) {
  var r = ee(e);
  r.current = e;
  var t = ee(function() {
    for (var n = [], a = arguments.length; a--; ) n[a] = arguments[a];
    return r.current.apply(r, n);
  });
  return t.current;
}
function ge(e, r) {
  r === void 0 && (r = !0);
  var t = e[0] === "-", n = t && r;
  e = e.replace("-", "");
  var a = e.split("."), i = a[0], u = a[1] || "";
  return {
    beforeDecimal: i,
    afterDecimal: u,
    hasNegation: t,
    addNegation: n
  };
}
function Ge(e) {
  if (!e)
    return e;
  var r = e[0] === "-";
  r && (e = e.substring(1, e.length));
  var t = e.split("."), n = t[0].replace(/^0+/, "") || "0", a = t[1] || "";
  return (r ? "-" : "") + n + (a ? "." + a : "");
}
function De(e, r, t) {
  for (var n = "", a = t ? "0" : "", i = 0; i <= r - 1; i++)
    n += e[i] || a;
  return n;
}
function ye(e, r) {
  return Array(r + 1).join(e);
}
function pe(e) {
  var r = e + "", t = r[0] === "-" ? "-" : "";
  t && (r = r.substring(1));
  var n = r.split(/[eE]/g), a = n[0], i = n[1];
  if (i = Number(i), !i)
    return t + a;
  a = a.replace(".", "");
  var u = 1 + i, v = a.length;
  return u < 0 ? a = "0." + ye("0", Math.abs(u)) + a : u >= v ? a = a + ye("0", u - v) : a = (a.substring(0, u) || "0") + "." + a.substring(u), t + a;
}
function xe(e, r, t) {
  if (["", "-"].indexOf(e) !== -1)
    return e;
  var n = (e.indexOf(".") !== -1 || t) && r, a = ge(e), i = a.beforeDecimal, u = a.afterDecimal, v = a.hasNegation, m = parseFloat("0." + (u || "0")), h = u.length <= r ? "0." + u : m.toFixed(r), s = h.split("."), S = i;
  i && Number(s[0]) && (S = i.split("").reverse().reduce(function(V, b, d) {
    return V.length > d ? (Number(V[0]) + Number(b)).toString() + V.substring(1, V.length) : b + V;
  }, s[0]));
  var c = De(s[1] || "", r, t), w = v ? "-" : "", l = n ? "." : "";
  return "" + w + S + l + c;
}
function z(e, r) {
  if (e.value = e.value, e !== null) {
    if (e.createTextRange) {
      var t = e.createTextRange();
      return t.move("character", r), t.select(), !0;
    }
    return e.selectionStart || e.selectionStart === 0 ? (e.focus(), e.setSelectionRange(r, r), !0) : (e.focus(), !1);
  }
}
var Ce = Le(function(e, r) {
  for (var t = 0, n = 0, a = e.length, i = r.length; e[t] === r[t] && t < a; )
    t++;
  for (; e[a - 1 - n] === r[i - 1 - n] && i - n > t && a - n > t; )
    n++;
  return {
    from: { start: t, end: a - n },
    to: { start: t, end: i - n }
  };
}), Ze = function(e, r) {
  var t = Math.min(e.selectionStart, r);
  return {
    from: { start: t, end: e.selectionEnd },
    to: { start: t, end: r }
  };
};
function qe(e, r, t) {
  return Math.min(Math.max(e, r), t);
}
function se(e) {
  return Math.max(e.selectionStart, e.selectionEnd);
}
function ze() {
  return typeof navigator < "u" && !(navigator.platform && /iPhone|iPod/.test(navigator.platform));
}
function Ie(e) {
  return {
    from: {
      start: 0,
      end: 0
    },
    to: {
      start: 0,
      end: e.length
    },
    lastValue: ""
  };
}
function Ae(e, r) {
  return e === void 0 && (e = " "), typeof e == "string" ? e : e[r] || " ";
}
function He(e) {
  var r = e.currentValue, t = e.formattedValue, n = e.currentValueIndex, a = e.formattedValueIndex;
  return r[n] === t[a];
}
function Je(e, r, t, n, a, i, u) {
  u === void 0 && (u = He);
  var v = a.findIndex(function(E) {
    return E;
  }), m = e.slice(0, v);
  !r && !t.startsWith(m) && (r = m, t = m + t, n = n + m.length);
  for (var h = t.length, s = e.length, S = {}, c = new Array(h), w = 0; w < h; w++) {
    c[w] = -1;
    for (var l = 0, V = s; l < V; l++) {
      var b = u({
        currentValue: t,
        lastValue: r,
        formattedValue: e,
        currentValueIndex: w,
        formattedValueIndex: l
      });
      if (b && S[l] !== !0) {
        c[w] = l, S[l] = !0;
        break;
      }
    }
  }
  for (var d = n; d < h && (c[d] === -1 || !i(t[d])); )
    d++;
  var F = d === h || c[d] === -1 ? s : c[d];
  for (d = n - 1; d > 0 && c[d] === -1; )
    d--;
  var D = d === -1 || c[d] === -1 ? 0 : c[d] + 1;
  return D > F ? F : n - D < F - n ? D : F;
}
function ie(e, r, t, n) {
  var a = e.length;
  if (r = qe(r, 0, a), n === "left") {
    for (; r >= 0 && !t[r]; )
      r--;
    r === -1 && (r = t.indexOf(!0));
  } else {
    for (; r <= a && !t[r]; )
      r++;
    r > a && (r = t.lastIndexOf(!0));
  }
  return r === -1 && (r = a), r;
}
function Qe(e) {
  for (var r = Array.from({ length: e.length + 1 }).map(function() {
    return !0;
  }), t = 0, n = r.length; t < n; t++)
    r[t] = !!(Q(e[t]) || Q(e[t - 1]));
  return r;
}
function Ee(e, r, t, n, a, i) {
  i === void 0 && (i = $);
  var u = We(function(l, V) {
    var b, d;
    return Ve(l) ? (d = "", b = "") : typeof l == "number" || V ? (d = typeof l == "number" ? pe(l) : l, b = n(d)) : (d = a(l, void 0), b = n(d)), { formattedValue: b, numAsString: d };
  }), v = be(function() {
    return u(J(e) ? r : e, t);
  }), m = v[0], h = v[1], s = function(l, V) {
    l.formattedValue !== m.formattedValue && h({
      formattedValue: l.formattedValue,
      numAsString: l.value
    }), i(l, V);
  }, S = e, c = t;
  J(e) && (S = m.numAsString, c = !0);
  var w = u(S, c);
  return Me(function() {
    h(w);
  }, [w.formattedValue]), [m, s];
}
function Xe(e) {
  return e.replace(/[^0-9]/g, "");
}
function Ye(e) {
  return e;
}
function Be(e) {
  var r = e.type;
  r === void 0 && (r = "text");
  var t = e.displayType;
  t === void 0 && (t = "input");
  var n = e.customInput, a = e.renderText, i = e.getInputRef, u = e.format;
  u === void 0 && (u = Ye);
  var v = e.removeFormatting;
  v === void 0 && (v = Xe);
  var m = e.defaultValue, h = e.valueIsNumericString, s = e.onValueChange, S = e.isAllowed, c = e.onChange;
  c === void 0 && (c = $);
  var w = e.onKeyDown;
  w === void 0 && (w = $);
  var l = e.onMouseUp;
  l === void 0 && (l = $);
  var V = e.onFocus;
  V === void 0 && (V = $);
  var b = e.onBlur;
  b === void 0 && (b = $);
  var d = e.value, F = e.getCaretBoundary;
  F === void 0 && (F = Qe);
  var D = e.isValidInputCharacter;
  D === void 0 && (D = Q);
  var E = e.isCharacterSame, _ = de(e, ["type", "displayType", "customInput", "renderText", "getInputRef", "format", "removeFormatting", "defaultValue", "valueIsNumericString", "onValueChange", "isAllowed", "onChange", "onKeyDown", "onMouseUp", "onFocus", "onBlur", "value", "getCaretBoundary", "isValidInputCharacter", "isCharacterSame"]), M = Ee(d, m, !!h, u, v, s), L = M[0], p = L.formattedValue, j = L.numAsString, U = M[1], P = ee(), W = ee({ formattedValue: p, numAsString: j }), G = function(o, f) {
    W.current = { formattedValue: o.formattedValue, numAsString: o.value }, U(o, f);
  }, X = be(!1), re = X[0], x = X[1], g = ee(null), C = ee({
    setCaretTimeout: null,
    focusTimeout: null
  });
  ce(function() {
    return x(!0), function() {
      clearTimeout(C.current.setCaretTimeout), clearTimeout(C.current.focusTimeout);
    };
  }, []);
  var O = u, T = function(o, f) {
    var y = parseFloat(f);
    return {
      formattedValue: o,
      value: f,
      floatValue: isNaN(y) ? void 0 : y
    };
  }, B = function(o, f, y) {
    o.selectionStart === 0 && o.selectionEnd === o.value.length || (z(o, f), C.current.setCaretTimeout = setTimeout(function() {
      o.value === y && o.selectionStart !== f && z(o, f);
    }, 0));
  }, R = function(o, f, y) {
    return ie(o, f, F(o), y);
  }, Y = function(o, f, y) {
    var I = F(f), k = Je(f, p, o, y, I, D, E);
    return k = ie(f, k, I), k;
  }, ue = function(o) {
    var f = o.formattedValue;
    f === void 0 && (f = "");
    var y = o.input, I = o.source, k = o.event, A = o.numAsString, N;
    if (y) {
      var K = o.inputValue || y.value, Z = se(y);
      y.value = f, N = Y(K, f, Z), N !== void 0 && B(y, N, f);
    }
    f !== p && G(T(f, A), { event: k, source: I });
  };
  ce(function() {
    var o = W.current, f = o.formattedValue, y = o.numAsString;
    (p !== f || j !== y) && G(T(p, j), {
      event: void 0,
      source: ae.props
    });
  }, [p, j]);
  var te = g.current ? se(g.current) : void 0, ne = typeof window < "u" ? Pe : ce;
  ne(function() {
    var o = g.current;
    if (p !== W.current.formattedValue && o) {
      var f = Y(W.current.formattedValue, p, te);
      o.value = p, B(o, f, p);
    }
  }, [p]);
  var fe = function(o, f, y) {
    var I = f.target, k = P.current ? Ze(P.current, I.selectionEnd) : Ce(p, o), A = Object.assign(Object.assign({}, k), { lastValue: p }), N = v(o, A), K = O(N);
    if (N = v(K, void 0), S && !S(T(K, N))) {
      var Z = f.target, q = se(Z), ve = Y(o, p, q);
      return Z.value = p, B(Z, ve, p), !1;
    }
    return ue({
      formattedValue: K,
      numAsString: N,
      inputValue: o,
      event: f,
      source: y,
      input: f.target
    }), !0;
  }, le = function(o, f) {
    f === void 0 && (f = 0);
    var y = o.selectionStart, I = o.selectionEnd;
    P.current = { selectionStart: y, selectionEnd: I + f };
  }, Fe = function(o) {
    var f = o.target, y = f.value, I = fe(y, o, ae.event);
    I && c(o), P.current = void 0;
  }, Oe = function(o) {
    var f = o.target, y = o.key, I = f.selectionStart, k = f.selectionEnd, A = f.value;
    A === void 0 && (A = "");
    var N;
    y === "ArrowLeft" || y === "Backspace" ? N = Math.max(I - 1, 0) : y === "ArrowRight" ? N = Math.min(I + 1, A.length) : y === "Delete" && (N = I);
    var K = 0;
    y === "Delete" && I === k && (K = 1);
    var Z = y === "ArrowLeft" || y === "ArrowRight";
    if (N === void 0 || I !== k && !Z) {
      w(o), le(f, K);
      return;
    }
    var q = N;
    if (Z) {
      var ve = y === "ArrowLeft" ? "left" : "right";
      q = R(A, N, ve), q !== N && o.preventDefault();
    } else y === "Delete" && !D(A[N]) ? q = R(A, N, "right") : y === "Backspace" && !D(A[N]) && (q = R(A, N, "left"));
    q !== N && B(f, q, A), w(o), le(f, K);
  }, Te = function(o) {
    var f = o.target, y = function() {
      var I = f.selectionStart, k = f.selectionEnd, A = f.value;
      if (A === void 0 && (A = ""), I === k) {
        var N = R(A, I);
        N !== I && B(f, N, A);
      }
    };
    y(), requestAnimationFrame(function() {
      y();
    }), l(o), le(f);
  }, Re = function(o) {
    o.persist && o.persist();
    var f = o.target, y = o.currentTarget;
    g.current = f, C.current.focusTimeout = setTimeout(function() {
      var I = f.selectionStart, k = f.selectionEnd, A = f.value;
      A === void 0 && (A = "");
      var N = R(A, I);
      N !== I && !(I === 0 && k === A.length) && B(f, N, A), V(Object.assign(Object.assign({}, o), { currentTarget: y }));
    }, 0);
  }, _e = function(o) {
    g.current = null, clearTimeout(C.current.focusTimeout), clearTimeout(C.current.setCaretTimeout), b(o);
  }, ke = re && ze() ? "numeric" : void 0, me = Object.assign({ inputMode: ke }, _, {
    type: r,
    value: p,
    onChange: Fe,
    onKeyDown: Oe,
    onMouseUp: Te,
    onFocus: Re,
    onBlur: _e
  });
  if (t === "text")
    return a ? H.createElement(H.Fragment, null, a(p, _) || null) : H.createElement("span", Object.assign({}, _, { ref: i }), p);
  if (n) {
    var je = n;
    return H.createElement(je, Object.assign({}, me, { ref: i }));
  }
  return H.createElement("input", Object.assign({}, me, { ref: i }));
}
function we(e, r) {
  var t = r.decimalScale, n = r.fixedDecimalScale, a = r.prefix;
  a === void 0 && (a = "");
  var i = r.suffix;
  i === void 0 && (i = "");
  var u = r.allowNegative, v = r.thousandsGroupStyle;
  if (v === void 0 && (v = "thousand"), e === "" || e === "-")
    return e;
  var m = oe(r), h = m.thousandSeparator, s = m.decimalSeparator, S = t !== 0 && e.indexOf(".") !== -1 || t && n, c = ge(e, u), w = c.beforeDecimal, l = c.afterDecimal, V = c.addNegation;
  return t !== void 0 && (l = De(l, t, !!n)), h && (w = Ue(w, h, v)), a && (w = a + w), i && (l = l + i), V && (w = "-" + w), e = w + (S && s || "") + l, e;
}
function oe(e) {
  var r = e.decimalSeparator;
  r === void 0 && (r = ".");
  var t = e.thousandSeparator, n = e.allowedDecimalSeparators;
  return t === !0 && (t = ","), n || (n = [r, "."]), {
    decimalSeparator: r,
    thousandSeparator: t,
    allowedDecimalSeparators: n
  };
}
function er(e, r) {
  e === void 0 && (e = "");
  var t = new RegExp("(-)"), n = new RegExp("(-)(.)*(-)"), a = t.test(e), i = n.test(e);
  return e = e.replace(/-/g, ""), a && !i && r && (e = "-" + e), e;
}
function rr(e, r) {
  return new RegExp("(^-)|[0-9]|" + Ne(e), "g");
}
function tr(e, r, t) {
  return e === "" ? !0 : !(r != null && r.match(/\d/)) && !(t != null && t.match(/\d/)) && typeof e == "string" && !isNaN(Number(e));
}
function ar(e, r, t) {
  var n;
  r === void 0 && (r = Ie(e));
  var a = t.allowNegative, i = t.prefix;
  i === void 0 && (i = "");
  var u = t.suffix;
  u === void 0 && (u = "");
  var v = t.decimalScale, m = r.from, h = r.to, s = h.start, S = h.end, c = oe(t), w = c.allowedDecimalSeparators, l = c.decimalSeparator, V = e[S] === l;
  if (Q(e) && (e === i || e === u) && r.lastValue === "")
    return e;
  if (S - s === 1 && w.indexOf(e[s]) !== -1) {
    var b = v === 0 ? "" : l;
    e = e.substring(0, s) + b + e.substring(s + 1, e.length);
  }
  var d = function(g, C, O) {
    var T = !1, B = !1;
    i.startsWith("-") ? T = !1 : g.startsWith("--") ? (T = !1, B = !0) : u.startsWith("-") && g.length === u.length ? T = !1 : g[0] === "-" && (T = !0);
    var R = T ? 1 : 0;
    return B && (R = 2), R && (g = g.substring(R), C -= R, O -= R), { value: g, start: C, end: O, hasNegation: T };
  }, F = d(e, s, S), D = F.hasNegation;
  n = F, e = n.value, s = n.start, S = n.end;
  var E = d(r.lastValue, m.start, m.end), _ = E.start, M = E.end, L = E.value, p = e.substring(s, S);
  e.length && L.length && (_ > L.length - u.length || M < i.length) && !(p && u.startsWith(p)) && (e = L);
  var j = 0;
  e.startsWith(i) ? j += i.length : s < i.length && (j = s), e = e.substring(j), S -= j;
  var U = e.length, P = e.length - u.length;
  e.endsWith(u) ? U = P : (S > P || S > e.length - u.length) && (U = S), e = e.substring(0, U), e = er(D ? "-" + e : e, a), e = (e.match(rr(l)) || []).join("");
  var W = e.indexOf(l);
  e = e.replace(new RegExp(Ne(l), "g"), function(g, C) {
    return C === W ? "." : "";
  });
  var G = ge(e, a), X = G.beforeDecimal, re = G.afterDecimal, x = G.addNegation;
  return h.end - h.start < m.end - m.start && X === "" && V && !parseFloat(re) && (e = x ? "-" : ""), e;
}
function nr(e, r) {
  var t = r.prefix;
  t === void 0 && (t = "");
  var n = r.suffix;
  n === void 0 && (n = "");
  var a = Array.from({ length: e.length + 1 }).map(function() {
    return !0;
  }), i = e[0] === "-";
  a.fill(!1, 0, t.length + (i ? 1 : 0));
  var u = e.length;
  return a.fill(!1, u - n.length + 1, u + 1), a;
}
function ir(e) {
  var r = oe(e), t = r.thousandSeparator, n = r.decimalSeparator, a = e.prefix;
  a === void 0 && (a = "");
  var i = e.allowNegative;
  if (i === void 0 && (i = !0), t === n)
    throw new Error(`
        Decimal separator can't be same as thousand separator.
        thousandSeparator: ` + t + ` (thousandSeparator = {true} is same as thousandSeparator = ",")
        decimalSeparator: ` + n + ` (default value for decimalSeparator is .)
     `);
  return a.startsWith("-") && i && (console.error(`
      Prefix can't start with '-' when allowNegative is true.
      prefix: ` + a + `
      allowNegative: ` + i + `
    `), i = !1), Object.assign(Object.assign({}, e), { allowNegative: i });
}
function or(e) {
  e = ir(e), e.decimalSeparator, e.allowedDecimalSeparators, e.thousandsGroupStyle;
  var r = e.suffix, t = e.allowNegative, n = e.allowLeadingZeros, a = e.onKeyDown;
  a === void 0 && (a = $);
  var i = e.onBlur;
  i === void 0 && (i = $);
  var u = e.thousandSeparator, v = e.decimalScale, m = e.fixedDecimalScale, h = e.prefix;
  h === void 0 && (h = "");
  var s = e.defaultValue, S = e.value, c = e.valueIsNumericString, w = e.onValueChange, l = de(e, ["decimalSeparator", "allowedDecimalSeparators", "thousandsGroupStyle", "suffix", "allowNegative", "allowLeadingZeros", "onKeyDown", "onBlur", "thousandSeparator", "decimalScale", "fixedDecimalScale", "prefix", "defaultValue", "value", "valueIsNumericString", "onValueChange"]), V = oe(e), b = V.decimalSeparator, d = V.allowedDecimalSeparators, F = function(x) {
    return we(x, e);
  }, D = function(x, g) {
    return ar(x, g, e);
  }, E = J(S) ? s : S, _ = c ?? tr(E, h, r);
  J(S) ? J(s) || (_ = _ || typeof s == "number") : _ = _ || typeof S == "number";
  var M = function(x) {
    return Ve(x) ? x : (typeof x == "number" && (x = pe(x)), _ && typeof v == "number" ? xe(x, v, !!m) : x);
  }, L = Ee(M(S), M(s), !!_, F, D, w), p = L[0], j = p.numAsString, U = p.formattedValue, P = L[1], W = function(x) {
    var g = x.target, C = x.key, O = g.selectionStart, T = g.selectionEnd, B = g.value;
    if (B === void 0 && (B = ""), (C === "Backspace" || C === "Delete") && T < h.length) {
      x.preventDefault();
      return;
    }
    if (O !== T) {
      a(x);
      return;
    }
    C === "Backspace" && B[0] === "-" && O === h.length + 1 && t && z(g, 1), v && m && (C === "Backspace" && B[O - 1] === b ? (z(g, O - 1), x.preventDefault()) : C === "Delete" && B[O] === b && x.preventDefault()), d != null && d.includes(C) && B[O] === b && z(g, O + 1);
    var R = u === !0 ? "," : u;
    C === "Backspace" && B[O - 1] === R && z(g, O - 1), C === "Delete" && B[O] === R && z(g, O + 1), a(x);
  }, G = function(x) {
    var g = j;
    if (g.match(/\d/g) || (g = ""), n || (g = Ge(g)), m && v && (g = xe(g, v, m)), g !== j) {
      var C = we(g, e);
      P({
        formattedValue: C,
        value: g,
        floatValue: parseFloat(g)
      }, {
        event: x,
        source: ae.event
      });
    }
    i(x);
  }, X = function(x) {
    return x === b ? !0 : Q(x);
  }, re = function(x) {
    var g = x.currentValue, C = x.lastValue, O = x.formattedValue, T = x.currentValueIndex, B = x.formattedValueIndex, R = g[T], Y = O[B], ue = Ce(C, g), te = ue.to, ne = function(fe) {
      return D(fe).indexOf(".") + h.length;
    };
    return S === 0 && m && v && g[te.start] === b && ne(g) < T && ne(O) > B ? !1 : T >= te.start && T < te.end && d && d.includes(R) && Y === b ? !0 : R === Y;
  };
  return Object.assign(Object.assign({}, l), {
    value: U,
    valueIsNumericString: !1,
    isValidInputCharacter: X,
    isCharacterSame: re,
    onValueChange: P,
    format: F,
    removeFormatting: D,
    getCaretBoundary: function(x) {
      return nr(x, e);
    },
    onKeyDown: W,
    onBlur: G
  });
}
function ur(e) {
  var r = or(e);
  return H.createElement(Be, Object.assign({}, r));
}
function fr(e, r) {
  var t = r.format, n = r.allowEmptyFormatting, a = r.mask, i = r.patternChar;
  if (i === void 0 && (i = "#"), e === "" && !n)
    return "";
  for (var u = 0, v = t.split(""), m = 0, h = t.length; m < h; m++)
    t[m] === i && (v[m] = e[u] || Ae(a, u), u += 1);
  return v.join("");
}
function lr(e, r, t) {
  r === void 0 && (r = Ie(e));
  var n = t.format, a = t.patternChar;
  a === void 0 && (a = "#");
  var i = r.from, u = r.to, v = r.lastValue;
  v === void 0 && (v = "");
  var m = function(b) {
    return n[b] === a;
  }, h = function(b, d) {
    for (var F = "", D = 0; D < b.length; D++)
      m(d + D) && Q(b[D]) && (F += b[D]);
    return F;
  }, s = function(b) {
    return b.replace(/[^0-9]/g, "");
  };
  if (!n.match(/\d/))
    return s(e);
  if ((v === "" || i.end - i.start === v.length) && e.length === n.length) {
    for (var S = "", c = 0; c < e.length; c++)
      if (m(c))
        Q(e[c]) && (S += e[c]);
      else if (e[c] !== n[c])
        return s(e);
    return S;
  }
  var w = v.substring(0, i.start), l = e.substring(u.start, u.end), V = v.substring(i.end);
  return "" + h(w, 0) + s(l) + h(V, i.end);
}
function vr(e, r) {
  var t = r.format, n = r.mask, a = r.patternChar;
  a === void 0 && (a = "#");
  var i = Array.from({ length: e.length + 1 }).map(function() {
    return !0;
  }), u = 0, v = -1, m = {};
  t.split("").forEach(function(c, w) {
    var l = void 0;
    c === a && (u++, l = Ae(n, u - 1), v === -1 && e[w] === l && (v = w)), m[w] = l;
  });
  for (var h = function(c) {
    return t[c] === a && e[c] !== m[c];
  }, s = 0, S = i.length; s < S; s++)
    i[s] = s === v || h(s) || h(s - 1);
  return i[t.indexOf(a)] = !0, i;
}
function cr(e) {
  var r = e.mask;
  if (r) {
    var t = r === "string" ? r : r.toString();
    if (t.match(/\d/g))
      throw new Error("Mask " + r + " should not contain numeric character;");
  }
}
function sr(e, r) {
  return e === "" ? !0 : !(r != null && r.match(/\d/)) && typeof e == "string" && (!!e.match(/^\d+$/) || e === "");
}
function dr(e) {
  e.mask, e.allowEmptyFormatting;
  var r = e.format, t = e.inputMode;
  t === void 0 && (t = "numeric");
  var n = e.onKeyDown;
  n === void 0 && (n = $);
  var a = e.patternChar;
  a === void 0 && (a = "#");
  var i = e.value, u = e.defaultValue, v = e.valueIsNumericString, m = de(e, ["mask", "allowEmptyFormatting", "format", "inputMode", "onKeyDown", "patternChar", "value", "defaultValue", "valueIsNumericString"]);
  cr(e);
  var h = function(l) {
    return vr(l, e);
  }, s = function(l) {
    var V = l.key, b = l.target, d = b.selectionStart, F = b.selectionEnd, D = b.value;
    if (d !== F) {
      n(l);
      return;
    }
    var E = d;
    if (V === "Backspace" || V === "Delete") {
      var _ = "right";
      if (V === "Backspace") {
        for (; E > 0 && r[E - 1] !== a; )
          E--;
        _ = "left";
      } else {
        for (var M = r.length; E < M && r[E] !== a; )
          E++;
        _ = "right";
      }
      E = ie(D, E, h(D), _);
    } else r[E] !== a && V !== "ArrowLeft" && V !== "ArrowRight" && (E = ie(D, E + 1, h(D), "right"));
    E !== d && z(b, E), n(l);
  }, S = J(i) ? u : i, c = v ?? sr(S, r), w = Object.assign(Object.assign({}, e), { valueIsNumericString: c });
  return Object.assign(Object.assign({}, m), {
    value: i,
    defaultValue: u,
    valueIsNumericString: c,
    inputMode: t,
    format: function(l) {
      return fr(l, w);
    },
    removeFormatting: function(l, V) {
      return lr(l, V, w);
    },
    getCaretBoundary: h,
    onKeyDown: s
  });
}
function gr(e) {
  var r = dr(e);
  return H.createElement(Be, Object.assign({}, r));
}
const yr = (e) => e != null && e.format ? /* @__PURE__ */ he(gr, { customInput: Se, ...e }) : /* @__PURE__ */ he(ur, { customInput: Se, ...e });
export {
  yr as N
};
