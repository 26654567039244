import { jsx as t } from "@emotion/react/jsx-runtime";
import { styled as o, Box as a, Typography as i } from "@mui/material";
const n = o(a)(({ theme: r }) => ({
  display: "flex",
  alignItems: "center",
  height: "2.5rem",
  width: "100%",
  background: r.palette.primary.main,
  color: r.palette.primary.contrastText,
  paddingInline: r.spacing(3)
})), c = ({ content: r, sx: e = [] }) => /* @__PURE__ */ t(n, { children: /* @__PURE__ */ t(a, { sx: [...Array.isArray(e) ? e : [e]], children: /* @__PURE__ */ t(i, { variant: "body2", children: r }) }) });
export {
  c as F
};
