import type { OrderProductOption } from '@/hooks/order/useOrderFormProducts';
import { MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type QuantityTableCellProps = {
  index: number;
  isReadOnly: boolean;
};

export function QuantityTableCell({ index, isReadOnly }: QuantityTableCellProps) {
  const { control, watch } = useFormContext();

  const product = watch(`products.${index}`);

  const options = [...Array(product.baseQuantity).keys()].map((x) => ({ label: x + 1, value: x + 1 }));
  return (
    <Controller
      name={`products.${index}.quantity`}
      disabled={!product.productId}
      control={control}
      rules={{
        required: 'Please select a quantity',
        validate: (value: OrderProductOption) => !!value
      }}
      render={({ field }) => {
        return (
          <Select<number>
            data-testid="quantityTableCell"
            {...field}
            value={field.value ?? ''}
            readOnly={isReadOnly}
            size="small"
            sx={{ minWidth: '62px' }}
          >
            {options.map(({ label, value }) => (
              <MenuItem value={value} key={`${product.productId}-${value}`}>
                {label}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    ></Controller>
  );
}
