import type React from 'react';

import { getTsAdminClient } from '@/api/admin.client';
import { getTsApiV1Client } from '@/api/api-v1.client';
import { getTsCustomerPortalClient } from '@/api/customer-portal.client';
import { getTsPatientPortalClient } from '@/api/patient-portal.client';

const { ReactQueryProvider: TsrReactQueryCustomerProvider } = getTsCustomerPortalClient();
const { ReactQueryProvider: TsrReactQueryPatientProvider } = getTsPatientPortalClient();
const { ReactQueryProvider: TsrReactQueryAdminProvider } = getTsAdminClient();
const { ReactQueryProvider: TsrReactQueryApiV1Provider } = getTsApiV1Client();

export const TsrReactQueryProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <TsrReactQueryApiV1Provider>
      <TsrReactQueryCustomerProvider>
        <TsrReactQueryPatientProvider>
          <TsrReactQueryAdminProvider>{children}</TsrReactQueryAdminProvider>
        </TsrReactQueryPatientProvider>
      </TsrReactQueryCustomerProvider>
    </TsrReactQueryApiV1Provider>
  );
};

export default TsrReactQueryProvider;
