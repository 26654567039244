/* eslint-disable react/no-unstable-nested-components */
import { Button, PaginationVariant, Table } from '@montugroup/design-system';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Checkbox, Stack, Typography, styled, useTheme } from '@mui/material';
import type { ColumnDef, ColumnMeta, Row, SortingState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import ListTableCell from '@/components/common/Table/ListTableCell';
import ActionsTableCell from '@/components/orders/tableCells/ActionsTableCell';
import ConsignmentTableCell from '@/components/orders/tableCells/ConsignmentTableCell';
import DoctorTableCell from '@/components/orders/tableCells/DoctorTableCell';
import OrderNumberTableCell from '@/components/orders/tableCells/OrderNumberTableCell';
import OrderStatusTableCell from '@/components/orders/tableCells/OrderStatusTableCell';
import PaymentStatusTableCell from '@/components/orders/tableCells/PaymentStatusTableCell';
import { useOrders } from '@/context/orders/Orders';
import AuthService from '@/services/authentication.service';

import { PatientDetailsTableCell } from '@/components/orders/tableCells/PatientDetailsTableCell';
import { PharmacyTableCell } from '@/components/orders/tableCells/PharmacyTableCell';
import { ProductsTableCell } from '@/components/orders/tableCells/ProductsTableCell';
import { SelectionTableCell } from '@/components/orders/tableCells/SelectionTableCell';
import { FF_ORDER_LIST_DISABLE_CHECKBOX } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { Order } from '@/services/data.service';

const columnMeta: ColumnMeta<Order, unknown> = {
  style: {
    padding: '4px 4px 4px 15px'
  }
};

const TABLE_OFFSET = 124;

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  flexGrow: 1
}));

function OrdersTable() {
  const {
    orders,
    orderCount,
    includeSasStatus,
    pageSize,
    shouldResetPageIndex,
    selectedOrders,
    handlePaginationModelChange,
    handleSortingOrderChange,
    loading,
    hasFiltersOrQuery,
    enableRecentOrders,
    toggleSelectAllOrders
  } = useOrders();
  const isDoc = AuthService.isDoc();
  const isAdmin = AuthService.isAdmin();
  const theme = useTheme();
  const containerRef = useRef<HTMLElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const { flags } = useFeatureFlags();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { top } = containerRef.current.getBoundingClientRect();
        setContainerHeight(window.innerHeight - TABLE_OFFSET - top);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Note: this is temporary change, until we dont get Design.
  // Once we get Design then need to hide individual checkbox as well
  const hideSelectionColumn = flags[FF_ORDER_LIST_DISABLE_CHECKBOX]
    ? ''
    : () => (
        <Checkbox
          disabled={orderCount === 0}
          onChange={toggleSelectAllOrders}
          checked={orderCount < pageSize ? selectedOrders.length === orderCount : selectedOrders.length === pageSize}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      );

  const columns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true
      },
      {
        accessorKey: 'selection',
        meta: columnMeta,
        header: hideSelectionColumn,
        cell: ({ row }) => <SelectionTableCell order={row.original} />,
        enableSorting: false
      },
      {
        accessorKey: 'order_code',
        meta: columnMeta,
        header: 'Order #',
        enableSorting: true,
        cell: ({ row }) => (
          <OrderNumberTableCell isDoc={isDoc} orderCode={row.original.order_code} orderId={row.original.id} />
        )
      },
      {
        accessorKey: 'order_date',
        meta: columnMeta,
        header: 'Date',
        enableSorting: true,
        cell: ({ row }) => (
          <Box minWidth={30} maxWidth={90}>
            {DateTime.fromISO(row.original.order_date).setZone('local').toFormat('dd-MMM-yyyy')}
          </Box>
        ),
        sortingFn: (rowA, rowB) => {
          const dateTimeA = DateTime.fromISO(rowA.original.order_date);
          const dateTimeB = DateTime.fromISO(rowB.original.order_date);
          if (dateTimeA < dateTimeB) {
            return -1;
          }
          if (dateTimeA == dateTimeB) {
            return 0;
          }
          return 1;
        }
      },
      {
        accessorKey: 'patient_name',
        meta: columnMeta,
        header: 'Patient Details',
        enableSorting: true,
        cell: ({ row }) => <PatientDetailsTableCell order={row.original} />
      },
      {
        accessorKey: 'Pharmacy.identifier_name',
        meta: columnMeta,
        header: 'Pharmacy',
        enableSorting: true,
        cell: ({ row }) => (
          <PharmacyTableCell
            name={row.original.pharmacy_name}
            code={row.original.identifier_name}
            id={row.original.pharmacy_id}
          />
        )
      },
      {
        accessorKey: 'product_name',
        meta: columnMeta,
        header: 'Product',
        enableSorting: false,
        cell: ({ row }) => (
          <Box sx={{ minWidth: 150, maxWidth: { xs: 200, lg: 500 } }}>
            <ProductsTableCell order={row.original} />
          </Box>
        )
      },
      {
        accessorKey: 'pharmacy_name',
        meta: columnMeta,
        header: 'Pharmacy',
        enableSorting: true
      },
      {
        accessorKey: 'prescriber_details',
        meta: columnMeta,
        header: 'Doctor',
        enableSorting: true,
        cell: ({ row }) => (
          <Box sx={{ maxWidth: { xs: 40, lg: 120 } }}>
            <DoctorTableCell order={row.original} />
          </Box>
        )
      },
      {
        accessorKey: 'payment_status',
        meta: columnMeta,
        header: 'Payment',
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <PaymentStatusTableCell
              paymentGateway={row.original.payment_gateway}
              paymentStatus={row.original.payment_status}
            />
          );
        }
      },
      {
        accessorKey: 'sasStatus',
        meta: columnMeta,
        header: 'Approval',
        enableSorting: false,
        cell: ({ row }) => (
          <ListTableCell
            items={row.original.sasStatus.map((status, i) => ({
              key: `approval-${row.original.id}-${i}`,
              value: status
            }))}
          />
        )
      },
      {
        accessorKey: 'orderStatus',
        meta: columnMeta,
        header: 'Status',
        enableSorting: true,
        cell: ({ row }) => <OrderStatusTableCell order={row.original} />
      },
      {
        accessorKey: 'consignment_code',
        meta: columnMeta,
        header: 'Consignment',
        enableSorting: true,
        cell: ({ row }) => (
          <ConsignmentTableCell
            consignmentCode={row.original.consignment_code}
            shipmentTrackingId={row.original.shipment_tracking_id}
            shipmentCarrier={row.original.shipment_carrier}
          />
        )
      },
      {
        accessorKey: 'actions',
        meta: columnMeta,
        header: '',
        enableSorting: false,
        cell: ({ row }) => <ActionsTableCell order={row.original} isDoc={isDoc} />
      }
    ],
    [toggleSelectAllOrders, selectedOrders.length, pageSize, isDoc]
  );

  const columnVisibility = {
    id: false,
    pharmacy_name: !isAdmin,
    prescriber_details: !isDoc,
    consignment_code: isAdmin,
    sasStatus: includeSasStatus,
    selection: isAdmin
  };

  const handleSortChange = (state: SortingState) => {
    // Workaround: if we disable sorting on the selection column, clicks don't make it to the checkbox
    // as such we enable it and filter out the sort events here
    const filteredSortingState = state.filter((sort) => sort.id !== 'selection');
    handleSortingOrderChange(filteredSortingState);
  };

  return (
    <Container ref={containerRef}>
      <Table
        data={orders}
        columns={columns}
        columnVisibility={columnVisibility}
        isScrollable={true}
        initialSort={[{ id: 'order_date', desc: true }]}
        maxHeight={containerHeight}
        showPagination
        manualPagination
        shouldResetPageIndex={shouldResetPageIndex}
        pageSize={pageSize}
        total={orderCount}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={handleSortChange}
        isLoading={loading}
        applyRowStyles={(row) => {
          const styles: any = {};
          if (selectedOrders.includes((row as Row<Order>).original.id)) {
            styles.backgroundColor = `${theme.palette.info.light} !important`;
          } else {
            styles.backgroundColor = 'inherit';
          }
          return styles;
        }}
        noDataFoundMessage={
          !hasFiltersOrQuery ? (
            <Stack gap={4} marginBottom={4}>
              <Typography>
                To search for orders, simply enter your search term in the search bar above or select a filter.
              </Typography>
              <Box>
                <Button onClick={enableRecentOrders} sx={{ padding: 0 }}>
                  <Box display="flex" gap={2}>
                    <Box display="flex" alignItems="center" sx={{ height: 20 }}>
                      View most recent orders
                    </Box>{' '}
                    <ArrowDownwardIcon fontSize="small" />
                  </Box>
                </Button>
              </Box>
            </Stack>
          ) : (
            'There are no orders matching your search criteria.'
          )
        }
      />
    </Container>
  );
}

export default OrdersTable;
