import useGetPatientForOrder from '@/hooks/order/useGetPatientForOrder';
import type { OrderProductOption, SkippedProductOption } from '@/hooks/order/useOrderFormProducts';
import { useOrderFormProducts } from '@/hooks/order/useOrderFormProducts';
import { useGetOrder } from '@/hooks/orders/useGetOrder';
import AuthService from '@/services/authentication.service';
import type { PatientForOrderDetails } from '@/services/order.service';
import type { Order } from '@/types';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type OrderMeta = {
  order: Order | undefined;
  patientOrder: PatientForOrderDetails | undefined;
  selectedProducts: OrderProductOption[];
  skippedProducts: SkippedProductOption[];
  availableProducts: OrderProductOption[];
  error: Error | null;
  isFetching: boolean;
};

export const OrderMetaContext = createContext<OrderMeta>({
  order: undefined,
  patientOrder: undefined,
  selectedProducts: [],
  skippedProducts: [],
  availableProducts: [],
  error: null,
  isFetching: false
});

export type OrderFormValues = {
  patient: {
    id: number | null;
    code: string | null;
    name: string;
  };
  products: OrderProductOption[];
  adminOrderCreationReasonId: number | null;
  paymentStatus: string;
  shopifyOrderConfirmationNumber: string | undefined;
  consignmentId: string | undefined;
  shipment: {
    trackingId: string | undefined;
    carrier: string | undefined;
  };
  notes: {
    id: number;
    note: string;
  }[];
};

const getFormDefaultValue = (
  order: Order | undefined,
  patientOrder: PatientForOrderDetails | undefined,
  products: OrderProductOption[]
) => {
  const patientDetails = order?.Patient ?? patientOrder?.patient;
  return {
    patient: {
      id: patientDetails?.id ?? null,
      code: patientDetails?.patient_code ?? '',
      name: patientDetails ? `${patientDetails.PatientUser.first_name} ${patientDetails.PatientUser.last_name}` : ''
    },
    products,
    adminOrderCreationReasonId: order?.adminOrderCreationReasonId ?? null,
    paymentStatus: order?.payment_status ?? '-',
    shopifyOrderConfirmationNumber: order?.orderManagementShopify?.shopify_order_confirmation_number,
    consignmentId: order?.consignmentID,
    shipment: {
      trackingId: order?.shipment_tracking_id,
      carrier: order?.shipment_carrier
    },
    notes:
      order?.order_notes
        ?.split('|')
        .filter((note) => note.trim() !== '')
        .map((note: string, id: number) => ({
          id,
          note
        })) ?? []
  };
};

export function OrderMetaProvider(props: PropsWithChildren) {
  const { id, patientId } = useParams();
  const { data: order, isFetching: orderIsFetching, error: orderError } = useGetOrder({ id: id as `${number}` });
  const {
    data: patientOrder,
    isFetching: patientIsFetching,
    error: patientError
  } = useGetPatientForOrder(patientId ? parseInt(patientId, 10) : undefined);
  const { selectedProducts, skippedProducts, availableProducts } = useOrderFormProducts(order, patientOrder);

  const formMethods = useForm<OrderFormValues>({
    values: getFormDefaultValue(order, patientOrder, selectedProducts)
  });

  const value = useMemo(
    () => ({
      order,
      patientOrder,
      selectedProducts,
      skippedProducts,
      availableProducts,
      isFetching: orderIsFetching || patientIsFetching,
      error: orderError ?? patientError
    }),
    [
      order,
      patientOrder,
      selectedProducts,
      skippedProducts,
      availableProducts,
      orderIsFetching,
      patientIsFetching,
      orderError,
      patientError
    ]
  );

  return (
    <OrderMetaContext.Provider value={value}>
      <FormProvider {...formMethods}>{props.children}</FormProvider>
    </OrderMetaContext.Provider>
  );
}

export const useOrderFormMeta = () => {
  const { id, refill, patientId } = useParams();
  const { order, patientOrder, error, isFetching, selectedProducts, skippedProducts, availableProducts } =
    useContext(OrderMetaContext);

  const isAdminOrderInAuthorisedPaymentStatus = Boolean(
    order?.payment_status === 'AUTHORISED' && order?.payment_initiated_path === 'ADMIN_INITIATED'
  );
  const isNewOrder = Boolean(!refill && patientId && !order?.patient_id);
  const isExistingOrder = Boolean(!!id && !refill && !patientId && order?.patient_id);
  const isEditing = AuthService.isAdmin() && (isAdminOrderInAuthorisedPaymentStatus || isNewOrder);

  return {
    order,
    patientOrder,
    selectedProducts,
    skippedProducts,
    availableProducts,
    error,
    isLoading: isFetching,
    isRefill: !!refill,
    isNewOrder,
    isExistingOrder,
    isAdminOrderInAuthorisedPaymentStatus: isAdminOrderInAuthorisedPaymentStatus,
    isAdminInititatedOrder: Boolean(order?.payment_initiated_path === 'ADMIN_INITIATED'),
    isEditing,
    isPaymentAuthorised: Boolean(order?.payment_status === 'AUTHORISED' || order?.payment_status === 'PAID')
  };
};
