import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { OrderProductOption } from '@/hooks/order/useOrderFormProducts';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type ProductTableCellProps = {
  productOption: OrderProductOption;
  selectedProducts: OrderProductOption[];
  index: number;
  isReadOnly: boolean;
  onChange: (value: OrderProductOption, index: number) => void;
};

export function ProductTableCell({
  productOption,
  selectedProducts,
  index,
  isReadOnly,
  onChange
}: ProductTableCellProps) {
  const { availableProducts } = useOrderFormMeta();
  const { control } = useFormContext();

  const options = useMemo(() => {
    return (availableProducts ?? [])
      .filter((option) => {
        if (option.productId === productOption.productId) {
          return true;
        }
        return !selectedProducts.find((product: OrderProductOption) => product.productId === option.productId);
      })
      .sort((a, b) => a.supplierName.localeCompare(b.supplierName));
  }, [availableProducts, selectedProducts, productOption.productId]);

  return (
    <Controller
      name={`products.${index}`}
      control={control}
      rules={{
        required: true,
        validate: (value: OrderProductOption) => (!value.productId ? 'Please select a product' : true)
      }}
      render={({ field: { value }, fieldState: { error } }) => (
        <Autocomplete
          sx={{ minWidth: 200 }}
          readOnly={isReadOnly}
          defaultValue={value}
          disableClearable
          options={options}
          groupBy={(option) => option.supplierName}
          isOptionEqualToValue={(option, value) => option.productId === value.productId}
          getOptionLabel={(option) => option.productName ?? ''}
          getOptionKey={(option) => option.productId ?? ''}
          getOptionDisabled={(option) => !!option.isDisabled}
          onChange={(_, value) => onChange(value, index)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, readOnly: isReadOnly }}
              size="small"
              placeholder="Search"
              fullWidth
              error={!!error}
            />
          )}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Stack gap={1}>
                  <Box>{option.productName}</Box>
                  <Typography variant="caption" color={(theme) => theme.palette.error.main}>
                    {option.subLabel}
                  </Typography>
                </Stack>
              </li>
            );
          }}
          slotProps={{
            paper: {
              sx: {
                '& .MuiAutocomplete-listbox': {
                  '& .MuiAutocomplete-option[aria-disabled="true"]': {
                    opacity: 1,
                    '& :first-child': {
                      '& :first-child': {
                        opacity: 0.5
                      }
                    }
                  }
                }
              }
            }
          }}
        />
      )}
    ></Controller>
  );
}
