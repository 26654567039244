/* eslint-disable jsdoc/require-returns */
/* eslint-disable jsdoc/require-param */
import { getTsApiV1Client } from '@/api/api-v1.client';
import { viteDomainSplitBaseUrl } from '@/constants/env';
import {
  FF_DSPLIT_ADMIN_PORTAL_REDIRECT,
  FF_DSPLIT_PATIENT_CUSTOMER_PORTAL_REDIRECT,
  FF_DSPLIT_PHARMACIST_PORTAL_REDIRECT
} from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';
import {
  removeSearchParam,
  replaceOrigin,
  useAuthFromMagicLink,
  useMagicLinkAuthNavigation
} from '@montugroup/circuit-shared';
import { useCallback, useLayoutEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFeatureFlags from './useFeatureFlags';
import useUser from './user/useUser';

const logger = new Logger('usePortalRedirector');

let authTokenAttempts = 0;

/**
 * Handles redirections between circuit portals & transfering authentication state across domains
 */
export function usePortalRedirector({ ready }: { ready: boolean }) {
  const { data: user } = useUser();
  const { flags } = useFeatureFlags();
  const { getAuthFromMagicLink, isPending: isPendingAuthFromMagicLink } = useAuthFromMagicLink();
  const { magicLinkAuthNavigation, isPending: isPendingMagicLinkAuthNavigation } = useMagicLinkAuthNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPendingDirectRedirection, setIsPendingDirectRedirection] = useState(false);

  const isPendingRedirection =
    isPendingAuthFromMagicLink || isPendingMagicLinkAuthNavigation || isPendingDirectRedirection;

  const removeTokenFromUrl = useCallback(() => {
    searchParams.delete('token');
    logger.info('removeTokenFromUrl', { searchParams: searchParams.toString(), authTokenAttempts });
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const navigateToRemoveTokenFromUrl = useCallback(() => {
    const urlWithoutToken = removeSearchParam({ url: window.location.href, key: 'token' });
    removeTokenFromUrl();
    logger.info('navigateToRemoveTokenFromUrl', { urlWithoutToken, authTokenAttempts });
    window.location.replace(urlWithoutToken);
  }, [removeTokenFromUrl]);

  // TODO: Remove overly cautious error handling and excessive logging if no issues are found
  useLayoutEffect(() => {
    if (isPendingRedirection) {
      return;
    }

    try {
      if (
        ready &&
        flags[FF_DSPLIT_ADMIN_PORTAL_REDIRECT] &&
        (matchPath({ path: '/login/admin' }, location.pathname) ||
          (user &&
            [USER_ROLES.admin, USER_ROLES.superAdmin, USER_ROLES.ptxSupportPartner].includes(user?.roleId) &&
            !(
              matchPath({ path: '/login/pharmacist' }, location.pathname) ||
              matchPath({ path: '/login' }, location.pathname) ||
              matchPath({ path: '/login/patient' }, location.pathname)
            )))
      ) {
        const currentURL = window.location.href;
        const newOrigin = viteDomainSplitBaseUrl('admin');
        const urlWithNewOrigin = replaceOrigin({ url: currentURL, newOrigin });

        logger.info('triggeredPortalRedirect', {
          fromPortal: 'circuit',
          toPortal: 'admin',
          currentURL,
          newOrigin,
          urlWithNewOrigin,
          triggeredByPathname: matchPath({ path: '/login/admin' }, location.pathname),
          triggeredByRole:
            user && [USER_ROLES.admin, USER_ROLES.superAdmin, USER_ROLES.ptxSupportPartner].includes(user?.roleId)
        });
        window.location.replace(urlWithNewOrigin);
        setIsPendingDirectRedirection(true);
        return;
      }
    } catch (error) {
      logger.error('triggeredPortalRedirect::error', { error, fromPortal: 'circuit', toPortal: 'admin' });
    }

    try {
      if (
        ready &&
        flags[FF_DSPLIT_PHARMACIST_PORTAL_REDIRECT] &&
        (matchPath({ path: '/login/pharmacist' }, location.pathname) ||
          (user &&
            USER_ROLES.pharmacist === user?.roleId &&
            !(
              matchPath({ path: '/login/admin' }, location.pathname) ||
              matchPath({ path: '/login' }, location.pathname) ||
              matchPath({ path: '/login/patient' }, location.pathname)
            )))
      ) {
        const currentURL = window.location.href;
        const newOrigin = viteDomainSplitBaseUrl('pharmacist');
        const urlWithNewOrigin = replaceOrigin({ url: currentURL, newOrigin });

        logger.info('triggeredPortalRedirect', {
          fromPortal: 'circuit',
          toPortal: 'pharmacist',
          currentURL,
          newOrigin,
          urlWithNewOrigin,
          triggeredByPathname: matchPath({ path: '/login/pharmacist' }, location.pathname),
          triggeredByRole: user && USER_ROLES.pharmacist === user?.roleId
        });
        window.location.replace(urlWithNewOrigin);
        setIsPendingDirectRedirection(true);
        return;
      }
    } catch (error) {
      logger.error('triggeredPortalRedirect::error', { error, fromPortal: 'circuit', toPortal: 'pharmacist' });
    }

    try {
      if (
        ready &&
        flags[FF_DSPLIT_PATIENT_CUSTOMER_PORTAL_REDIRECT] &&
        !(
          matchPath({ path: '/login/admin' }, location.pathname) ||
          matchPath({ path: '/login/pharmacist' }, location.pathname)
        )
      ) {
        if (user && user.roleId === USER_ROLES.patient) {
          const currentURL = window.location.href;
          const newOrigin = viteDomainSplitBaseUrl('patient');

          logger.info('triggeredPortalRedirect::magicLinkAuthNavigation', {
            fromPortal: 'circuit',
            toPortal: 'patient',
            currentURL,
            newOrigin
          });
          magicLinkAuthNavigation({
            currentURL,
            newOrigin,
            apiClient: getTsApiV1Client(),
            authorization: AuthService.getUser()?.token
          });
        } else {
          const currentURL = window.location.href;
          const newOrigin = viteDomainSplitBaseUrl('patient');
          const urlWithNewOrigin = replaceOrigin({ url: currentURL, newOrigin });

          logger.info('triggeredPortalRedirect', {
            fromPortal: 'circuit',
            toPortal: 'patient',
            currentURL,
            newOrigin,
            urlWithNewOrigin
          });
          window.location.replace(urlWithNewOrigin);
          setIsPendingDirectRedirection(true);
        }
        return;
      }
    } catch (error) {
      logger.error('triggeredPortalRedirect::error', { error, fromPortal: 'circuit', toPortal: 'patient' });
    }

    const token = searchParams.get('token') as string;
    if (typeof token === 'string') {
      try {
        (async () => {
          logger.info('hasTokenSearchParam', {
            tokenLength: token.length,
            tokenCharacters: [...new Set(token.split('').sort())].join('')
          });

          const auth = await getAuthFromMagicLink({ token, apiClient: getTsApiV1Client() });
          logger.info('getAuthFromMagicLink', { hasAuth: !!auth });

          AuthService.storeAuthState(auth);
          logger.info('AuthService.storeAuthState');
          removeTokenFromUrl();
        })();
      } catch (error) {
        logger.error('hasTokenSearchParam::error', { error });
        removeTokenFromUrl();
      } finally {
        authTokenAttempts++;
        logger.info(`getAuthFromMagicLink::hasAttemptedTokenRemoval::${authTokenAttempts}`, { authTokenAttempts });

        if (authTokenAttempts >= 3) {
          // If something is preventing a graceful removal of the token from the URL, try a hard navigation
          navigateToRemoveTokenFromUrl();
        }
      }
    }
  }, [
    flags,
    getAuthFromMagicLink,
    isPendingRedirection,
    location.pathname,
    magicLinkAuthNavigation,
    navigate,
    navigateToRemoveTokenFromUrl,
    ready,
    removeTokenFromUrl,
    searchParams,
    user
  ]);

  return {
    isPendingRedirection
  };
}
