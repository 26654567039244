import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { DiscountReason, OrderDiscount } from '@/types/discount';
import { Table } from '@montugroup/design-system';
import { Box, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

type OrderDiscountData = Pick<OrderDiscount, 'id' | 'discount_amount'> & {
  discount_type: string;
  DiscountReason: Pick<DiscountReason, 'id' | 'reason'>;
};
const discountReason = (discountReasonId: number) => {
  if (discountReasonId === 1 || discountReasonId === 2) {
    return 'Issue with Product';
  } else if (discountReasonId === 7) {
    return 'Lost/Stolen Order';
  }
};

export const OrderDiscounts = ({ selectedReasonId }: { selectedReasonId: number }) => {
  const { isLoading } = useOrderFormMeta();

  const columns: ColumnDef<OrderDiscountData>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: false
      },
      {
        header: 'Discount Amount',
        accessorKey: 'discount_amount',
        cell: () => <Typography variant="body1">100%</Typography>
      },
      {
        header: 'Product',
        accessorKey: 'discount_type',
        cell: () => <Typography>Full Order</Typography>
      },
      {
        header: 'Reason',
        accessorKey: 'discount_reason',
        cell: ({ row }) => <Typography>{discountReason(row.original.DiscountReason.id)}</Typography>
      }
    ],
    []
  );

  const orderDiscounts: OrderDiscountData[] = [
    {
      id: 1,
      discount_amount: 100,
      discount_type: 'Full Order',
      DiscountReason: { reason: 'Issue with Product', id: selectedReasonId }
    }
  ];

  return (
    <Box>
      <Typography variant="body1">Order Discounts</Typography>
      <Table
        data={orderDiscounts}
        columns={columns}
        columnVisibility={{ id: false }}
        isLoading={isLoading}
        hasRowBackgroundColor={false}
        elevation={0}
      />
    </Box>
  );
};

export default OrderDiscounts;
