import { BrandThemeProvider } from '@montugroup/themes';
import { Receipt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { tmpSxSpecificityForce } from '@/theme';

export function CTASection() {
  const { key } = usePortalPartnerBrand();
  return (
    <BrandThemeProvider theme={key}>
      <Box marginBottom={6}>
        <Button
          component={RouterLink}
          to="/patient/refill"
          size="large"
          variant="contained"
          startIcon={<Receipt />}
          sx={{
            padding: (theme) => `${theme.spacing(4, 10)} ${tmpSxSpecificityForce}`,
            width: { xs: '100%', md: 'auto' }
          }}
        >
          Start your order
        </Button>
      </Box>
    </BrandThemeProvider>
  );
}

export default CTASection;
