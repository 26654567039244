import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';

type ActionsTableCellProps = {
  handleClick: () => void;
};

export function ActionsTableCell({ handleClick }: ActionsTableCellProps) {
  const { watch } = useFormContext();
  const products = watch('products');

  if (products.length === 1) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="end">
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}
