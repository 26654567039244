import { alternaleaf, BrandThemeProvider } from '@montugroup/themes';
import { Alert, AlertTitle, Box, Link, styled, useMediaQuery, useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import { Suspense, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import GlobalContentCard from '@/components/braze/GlobalContentCard';
import Footer from '@/components/layout/footer';
import { ContentWrapper, MainContentBox } from '@/components/layout/LayoutStyledComponents';
import MainContentWrapper from '@/components/layout/MainContentWrapper';
import NavbarContainer from '@/components/layout/NavbarContainer';
import Notices from '@/components/layout/Notices';
import settings from '@/constants/constants';
import {
  FF_ENABLE_PATIENT_ORDERS_PAGE,
  FF_ORDERMEDICATIONPAGE2024,
  FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT,
  FF_PATIENT_REFILL_CHECKOUT_BANNER,
  FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT,
  FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION,
  FF_USE_CUSTOMER_HOOK
} from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

import CircleLoader from '../common/circleLoader';
import AlertSnackbarProvider from '../error/AlertSnackbarProvider';
import MobileAppBar from '../layout/navbar/MobileAppBar';
import PortalPartnerLayout from '../layout/portal-partner-layout/PortalPartnerLayout';
import Sidebar from '../layout/sidebar/Sidebar';

import useCustomer from '@/hooks/user/useCustomer';
import useUser from '@/hooks/user/useUser';
import Error404 from '@/pages/error/404';
import { AlertOutlet } from '../layout/portal-partner-layout/contexts/portal-layout-context';
import { isPatientPortalPartnerPage } from './utils';

const logger = new Logger('requireAuth');

type Props = {
  allowedRoles: number[];
  displayGlobalContentCard?: boolean;
  children: ReactNode;
};

const LegacyAlertContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '0 5%',
  [theme.breakpoints.up('md')]: {
    padding: '0 10%'
  }
}));

// TODO: must add next param to redirect the user when he logged in
function RequireAuth({ children: Children, allowedRoles, displayGlobalContentCard }: Props) {
  const { data: user, isLoading: userIsLoading } = useUser();
  const { data: customer } = useCustomer();
  const navigate = useNavigate();
  const { flags } = useFeatureFlags();
  const isLargeView = useMediaQuery(alternaleaf.breakpoints.up('md'));
  const location = useLocation();
  const theme = useTheme();

  const { name: partnerName } = usePortalPartnerBrand();

  const ffCheckoutBannerText =
    flags[FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT] || ffDefaults[FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT];
  const ffPatientRefillCheckoutBanner = flags[FF_PATIENT_REFILL_CHECKOUT_BANNER];
  const isOrderMedicationPage2024 = flags[FF_ORDERMEDICATIONPAGE2024];
  const ffPatientDischargeCircuitCheckoutDisabledAlert = flags[FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT];
  const isHomePage = location.pathname === '/home';
  const removeContainerPadding = isHomePage || location.pathname === '/patient/consultations';
  const removeExtraBottomContainerPadding = location.pathname === '/patient/consultations';

  const moreUserData = JSON.parse(localStorage.getItem('moreUserData') || '{}');
  const { patient } = moreUserData || {};
  const isDischarged = flags[FF_USE_CUSTOMER_HOOK] ? customer?.isDischarged : patient?.is_discharge;

  // TODO: Refactor into abstraction based on RFC: https://montugroup.atlassian.net/wiki/spaces/Eng/pages/493813761/RFC-017+Standardised+Error+Management+in+Circuit+Frontend
  const Banner = useCallback(() => {
    const alertPriority = [
      {
        Component: (
          <Alert
            severity="error"
            sx={{
              width: '100%',
              backgroundColor: 'var(--_components-alert-error-background, #F9E6E6)',
              borderRadius: '4px'
            }}
          >
            {isOrderMedicationPage2024 && <AlertTitle>Checkout unavailable</AlertTitle>}
            {isOrderMedicationPage2024
              ? `You have been discharged from ${partnerName}.`
              : `Checkout unavailable as you have been discharged from ${partnerName}.`}{' '}
            If this is incorrect, please{' '}
            <Link rel="noopener noreferrer" target="_blank" href={`${settings.support.site.url}/requests/new`}>
              contact us
            </Link>
            .
          </Alert>
        ),
        enabled: ffPatientDischargeCircuitCheckoutDisabledAlert && isDischarged,
        routes: ['/patient/refill', 'patient/order-detail/']
      },
      {
        Component: (
          <Alert
            severity="warning"
            sx={{
              width: '100%',
              backgroundColor: 'var(--_components-alert-warning-background, #FFF4E5)',
              borderRadius: '4px'
            }}
          >
            {ffCheckoutBannerText}
          </Alert>
        ),
        enabled: ffPatientRefillCheckoutBanner,
        routes: ['/patient/refill']
      }
    ];

    return (
      alertPriority.find(({ enabled, routes }) => enabled && routes.find((route) => location.pathname.includes(route)))
        ?.Component || null
    );
  }, [
    ffCheckoutBannerText,
    ffPatientDischargeCircuitCheckoutDisabledAlert,
    ffPatientRefillCheckoutBanner,
    isDischarged,
    isOrderMedicationPage2024,
    location.pathname,
    partnerName
  ]);

  useEffect(() => {
    if (!user && !userIsLoading) {
      logger.info('useEffect logout');
      AuthService.logout({ navigate, expired: true });
    }
  }, [location.pathname, navigate, user, userIsLoading]);

  if (userIsLoading || !user) {
    return <CircleLoader />;
  }

  const userRoles = AuthService.getUserRoles();
  const userRole = user?.roleId || null;

  const isUmedsBrand = userRole === userRoles.patient;
  const isPortalPartnerPage = isPatientPortalPartnerPage(location.pathname);
  const isPortalPartnerLayoutForOrdersHistory =
    location.pathname === '/patient/orders' && flags[FF_ENABLE_PATIENT_ORDERS_PAGE];
  const bannerEnabled = Object.values(Banner).some(({ enabled }) => enabled);

  if (userRole !== null && allowedRoles.indexOf(userRole) === -1) {
    logger.info('userRole does not have access to route', {
      userRole,
      allowedRoles,
      pathname: location.pathname
    });

    if (flags[FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION] !== true) {
      // clearUserDataFromStorage is here to stop users getting stuck and the only way to get out of it would be to clear their cache/local storage
      logger.info('running clearUserDataFromStorage due to prevent_logout_on_unauthed_navigation', {
        prevent_logout_on_unauthed_navigation: flags[FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION]
      });
      AuthService.clearUserDataFromStorage();
    }

    return <Error404 />;
  }

  const MainContentContainer = (
    <div
      id="main-content"
      className={userRole === userRoles.patient ? `patient-login ${!isHomePage ? 'main-content' : ''}` : 'main-content'}
    >
      {bannerEnabled && (
        <LegacyAlertContainer>
          <Box style={{ paddingTop: theme.spacing(8) }}>
            <Banner />
          </Box>
        </LegacyAlertContainer>
      )}
      {displayGlobalContentCard && <GlobalContentCard />}
      <Suspense fallback={<CircleLoader />}>{Children}</Suspense>
    </div>
  );

  // TODO: Refactor layout logic out of the AuthenticatedRoute Component
  return (
    <>
      <Notices />
      {isUmedsBrand ? (
        <BrandThemeProvider theme={alternaleaf}>
          <AlertSnackbarProvider>
            {(isPortalPartnerPage && flags[FF_ORDERMEDICATIONPAGE2024]) || isPortalPartnerLayoutForOrdersHistory ? (
              <PortalPartnerLayout>
                <PortalPartnerLayout.ParentHeader />
                <PortalPartnerLayout.PartnerHeader />
                <PortalPartnerLayout.Alert>
                  <Banner />
                  <AlertOutlet />
                </PortalPartnerLayout.Alert>
                <PortalPartnerLayout.Content>
                  {displayGlobalContentCard && <GlobalContentCard />}
                  <Suspense fallback={<CircleLoader />}>{Children}</Suspense>
                </PortalPartnerLayout.Content>
              </PortalPartnerLayout>
            ) : (
              <MainContentBox>
                {isLargeView ? <Sidebar /> : <MobileAppBar />}
                <ContentWrapper
                  removePadding={removeContainerPadding}
                  removeExtraBottomPadding={removeExtraBottomContainerPadding}
                >
                  {bannerEnabled && (
                    <LegacyAlertContainer>
                      <Banner />
                    </LegacyAlertContainer>
                  )}
                  {displayGlobalContentCard && <GlobalContentCard />}
                  <Suspense fallback={<CircleLoader />}>{Children}</Suspense>
                </ContentWrapper>
              </MainContentBox>
            )}
          </AlertSnackbarProvider>
        </BrandThemeProvider>
      ) : (
        <MainContentWrapper>
          <NavbarContainer />
          {MainContentContainer}
          <Footer />
        </MainContentWrapper>
      )}
    </>
  );
}

export default RequireAuth;
