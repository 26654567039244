import { BrandThemeProvider } from '@montugroup/themes';
import { Alert, AlertTitle, Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import type { FormEvent } from 'react';
import { useLayoutEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import usePharmacistForm from '@/hooks/pharmacist/usePharmacistForm';
import usePharmacistLogin from '@/hooks/pharmacist/usePharmacistLogin';
import useSessionExpirationCheck from '@/hooks/pharmacist/useSessionExpirationCheck';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

import { viteDomainSplitBaseUrl } from '@/constants/env';
import { FF_PHARMACY_PORTAL_REDIRECT_BANNER_DATE } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import LoginFailure from '../login/components/LoginFailure';
import { AuthCardContainer, AuthPageContainer, Background, CentreContainer } from './PharmacistStyles';

function PharmacistSignInForm() {
  const { key: brandName, Icon: Bg, Logo: TopLogo } = usePortalPartnerBrand();
  const navigate = useNavigate();

  const { data, errors, handleUserInput, setErrors, validator } = usePharmacistForm({
    email: '',
    password: '',
    loginType: 'pharmacist'
  });

  const { login, isPending, error } = usePharmacistLogin();

  const { flags } = useFeatureFlags();
  const pharmacistPortalRedirectBannerDate = flags[FF_PHARMACY_PORTAL_REDIRECT_BANNER_DATE];

  const ispharmacistPortalRedirectEnabled = !!pharmacistPortalRedirectBannerDate;

  useSessionExpirationCheck();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        email: validator.message('email', data.email, 'required|email'),
        password: validator.message('password', data.password, 'required')
      });
      return;
    }

    await login(data);
  };

  useLayoutEffect(() => {
    const loggedIn = AuthService.getUser();
    if (loggedIn && loggedIn.user && loggedIn.user.role_id === USER_ROLES.pharmacist) {
      navigate('/pharmacist/orders', { replace: true });
    }
  }, [navigate]);

  return (
    <BrandThemeProvider theme={brandName}>
      {ispharmacistPortalRedirectEnabled && (
        <Alert
          severity="info"
          variant="filled"
          sx={{ cursor: 'pointer', borderRadius: 0 }}
          onClick={() => (window.location.href = viteDomainSplitBaseUrl('pharmacist'))}
        >
          <AlertTitle sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            Click here to try the new Pharmacist Portal
          </AlertTitle>
          <Typography variant="body2" display="inline">
            Pharmacist login will be decomissioned on{' '}
            <Typography component="span" variant="body2" fontWeight="bold" display="inline">
              {pharmacistPortalRedirectBannerDate}
            </Typography>
          </Typography>
        </Alert>
      )}
      <AuthPageContainer>
        <CentreContainer>
          <AuthCardContainer>
            <Stack mb={10}>
              <TopLogo width="150px" height="auto" fillColor={(theme) => theme.palette.primary.main} />
            </Stack>

            <Box>
              <LoginFailure error={error} showPharmacistSupportText />
              <Typography color="primary" variant="h5" align="left">
                Pharmacist login
              </Typography>

              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleUserInput('text')}
                  value={data.email}
                  autoComplete="email"
                  sx={{
                    marginBottom: 0
                  }}
                />
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleUserInput('text')}
                  value={data.password}
                  autoComplete="off"
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: 2
                  }}
                  color="secondary"
                  disabled={isPending}
                  size="large"
                >
                  Login
                </Button>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ alignSelf: 'stretch', mt: 4 }}
                >
                  <Link component={RouterLink} to={`/forgot-password/${data.loginType}`} color="primary">
                    Forgot password?
                  </Link>
                </Stack>
              </form>
            </Box>
          </AuthCardContainer>
        </CentreContainer>
      </AuthPageContainer>
      <Background>
        <Bg />
      </Background>
    </BrandThemeProvider>
  );
}

export default PharmacistSignInForm;
